<template>
  <div class="login-wap">
    <div class="login">
      <el-image
        @click="closelogin"
        class="close-icon"
        :src="require('@/assets/close.png')"
        :fit="'fill'"
      />
      <div class="login-title">
        <el-image
          style="width: 40px; height: 40px; margin-right: 8px"
          :src="require('@/assets/logo.png')"
          :fit="'fill'"
        />
        妙语 AI
      </div>
      <div class="login-desc">
        <!-- <div class="login-desc-text">微信扫码登录</div> -->
        <!-- <div class="login-desc-text">手机验证码登录</div> -->
      </div>
      <div class="login-phone-number">
        <!-- <el-input v-model="input" placeholder="请输入手机号" /> -->
        <div class="login-phone-title">手机号</div>
        <div class="login-phone-box">
          <input
            class="login-phone-input"
            type="number"
            inputmode="numeric"
            pattern="[0-9]*"
            v-model="phone"
            placeholder="请输入手机号"
            maxlength="11"
            @blur="inputBlur"
            @input="inputPhone"
          />
          <img
            v-if="this.phone.trim() != ''"
            @click.stop="clearinput"
            class="login-phone-icon"
            :src="require('@/assets/close2.png')"
            alt=""
          />
        </div>
      </div>
      <div class="login-phone-number active">
        <div class="login-phone-title">验证码</div>
        <div class="login-phone-input-box">
          <input
            class="login-phone-input"
            type="number"
            pattern="[0-9]*"
            inputmode="numeric"
            v-model="code"
            placeholder="请输入验证码"
            maxlength="6"
            @input="inputCode"
          />
          <!-- <div class="login-error-phone-input-button">
            获取验证码
          </div> -->
          <div
            v-if="cangetcode"
            @click="getCode"
            class="login-phone-input-button"
          >
            {{ buttonText }}
          </div>
          <div v-else style="color: #ababab" class="login-phone-input-button">
            发送验证码
          </div>
        </div>
      </div>
      <div v-if="canlogin" class="login-button active" @click="loginchat">
        登录/注册
      </div>
      <div v-else class="login-button">登录/注册</div>
      <div class="wx-login-button" @click="wxLogin">
        <img class="wx-login-button-icon" :src="require('@/assets/weixin_icon.png')" alt="" />
        微信扫码登录
      </div>
      <div class="login-text">未注册手机验证后自动登录，注册即代表同意</div>

      <div class="login-agreement-box">
        <a
          href="https://quip.weixuanpro.cn/service-agreement.html"
          target="_blank"
          class="login-agreement"
          >《服务协议》</a
        >
        <a
          href="https://quip.weixuanpro.cn/protocol-privacy.html"
          target="_blank"
          class="login-agreement"
          >《隐私政策》</a
        >
      </div>
    </div>
  </div>
</template>

<script>
import { loginrequest } from "/api/request/index.js";
import axios from "axios";
import md5 from "md5";
import { appMsg, apiMap } from "/api/config/apiMap.js";
import $ from "jquery";

export default {
  name: "login",
  props: {},
  data() {
    return {
      showlogin: true,
      phone: "", // 在setup函数中定义组件的data
      code: "",
      counting: false, // 倒计时状态
      count: 60, // 倒计时秒数
      canlogin: false,
      cangetcode: false,
      counting: false, // 是否正在倒计时
      countdown: 60, // 倒计时秒数
      userinfo: {},
    };
  },
  computed: {
    buttonText() {
      return this.counting
        ? `${this.count} 秒`
        : this.count === 0
        ? "重新获取验证码"
        : "获取验证码";
    },
  },
  methods: {
    clearinput() {
      this.phone = "";
    },
    inputPhone(e) {
      this.phone = this.phone.replace(/\D/g, "");
      if (this.phone.length > 11) {
        this.phone = this.phone.slice(0, 11); // 截取前11位
      }
      const phoneRegex = /^1\d{10}$/;

      // 检查手机号码是否符合正则表达式

      this.cangetcode = false;

      if (!phoneRegex.test(this.phone) && this.phone.length >= 11) {
        // 手机号码格式不正确，显示错误信息
        this.$emit("phoneError", "手机号格式不正确");
        return;
      }
      if (phoneRegex.test(this.phone) && this.phone.length >= 11) {
        // 手机号码格式不正确，显示错误信息
        this.cangetcode = true;
        return;
      }
      if (this.phone.length >= 11 && this.code.length >= 6) {
        this.canlogin = true;
      } else {
        this.canlogin = false;
      }
    },
    wxLogin(){
      window.location.href = 'https://open.weixin.qq.com/connect/qrconnect?appid=wxb885b66d32455c88&redirect_uri=https://chat.joychatai.com/&response_type=code&scope=snsapi_login&state=STATE#wechat_redirect'
    },
    inputCode() {
      if (this.code.length > 6) {
        this.code = this.code.slice(0, 6); // 截取前11位
      }
      if (this.phone.length >= 11 && this.code.length >= 6) {
        this.canlogin = true;
      } else {
        this.canlogin = false;
      }
    },
    inputBlur() {},
    loginchat() {
      this.showlogin = false;
      // this.$emit("enterlogin");
      const loading = this.$loading({
        lock: true,
        text: "Loading",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      let that = this;
      const sign = md5(this.phone + this.code + appMsg.websocket);

      this.$axios({
        method: "post", //指定请求方式
        url: apiMap.host + "/api/app/v1/login/phone", //请求接口（相对接口，后面会介绍到）
        headers: {
          from: 1,
          version: "1.0.1",
          "Content-Type": "application/json",
          serial: new Date().getTime() + "",
        },
        data: {
          phone: this.phone, // 手机号
          code: this.code, // 1: 获取登录验证码，2: 获取授权验证码
          sign: sign,
        },
      })
        .then((res) => {
          // if (res.data.errorCode == 10000 || res.data.code == 400) {
          //   that.loginout;
          //   return
          // }
          console.log(res.status, res.data, res.data.data.identification);
          if (res.status == 200) {
            localStorage.setItem("token", res.data.data.token);
            localStorage.setItem("visitorId", res.data.data.visitorId);
            localStorage.setItem(
              "identification",
              res.data.data.identification
            );
            document.cookie = "sessionid=" + res.data.data.token + ";";
          } else {
            this.$emit("phoneError", "登陆失败，请稍后重试");
            return;
          }
          const sign2 = md5(res.data.data.identification + appMsg.websocket);

          console.log(res.data.data.identification);
          that
            .$axios({
              method: "GET", //指定请求方式
              url: apiMap.host + "/api/app/v1/user/info", //请求接口（相对接口，后面会介绍到）
              headers: {
                from: 1,
                version: "1.0.1",
                visitorId: res.data.data.visitorId,
                Authorization: "basic " + res.data.data.token,
                serial: new Date().getTime() + "",
              },
              params: {
                identification: res.data.data.identification, // 手机号
                sign: sign2,
              },
            })
            .then((res2) => {
              // if (res2.data.errorCode == 10000 || res2.data.status == 400) {
              //   that.loginout();
              //   return
              // }
              loading.close();
              localStorage.setItem("userinfo", JSON.stringify(res2.data.data));
              if (res2.data.data.vipType > 1) {
                this.$emit("login-success", res2.data.data);
              } else {
                this.$emit("login-error", res2.data.data);
              }
              this.phone = "";
              this.code = "";
            })
            .catch((error) => {
              // 捕获请求错误
              loading.close();
              console.error("请求失败:", error);
              // 在这里可以处理请求失败的逻辑，例如显示错误信息
            });
        })
        .catch((error) => {
          // 捕获请求错误
          loading.close();
          console.error("请求失败:", error);
          this.$emit("phoneError", "验证码错误");
          // 在这里可以处理请求失败的逻辑，例如显示错误信息
        });
    },
    closelogin() {
      this.$emit("enterlogin");
    },
    getCode() {
      let that = this;
      const time = new Date().getTime();
      const sign = md5(this.phone + "1" + time + appMsg.websocket);
      console.log(this.phone + "1" + time + appMsg.websocket);
      console.log(sign);
      if (this.phone.length == 11) {
        this.$axios({
          method: "post", //指定请求方式
          url: apiMap.host + "/api/app/v1/get/verify/code", //请求接口（相对接口，后面会介绍到）
          data: {
            phone: this.phone, // 手机号
            type: 1, // 1: 获取登录验证码，2: 获取授权验证码
            time: time,
            sign: sign,
          },
          headers: {
            from: 1,
            version: "1.0.1",
            serial: new Date().getTime() + "",
          },
        }).then((res) => {
          // if (res.data.errorCode == 10000 || res.data.status == 400) {
          //   that.loginout;
          //   return
          // }

          if (!that.counting) {
            that.counting = true;
            this.$emit("phoneError", "验证码已发送");
            const timer = setInterval(() => {
              if (that.count > 1) {
                that.count -= 1;
              } else {
                that.counting = false;
                that.count = 60; // 重置倒计时时间
                clearInterval(timer); // 清除定时器
              }
            }, 1000);
          }
        });
      } else {
        this.$message.error("手机号格式不正确");
      }
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="stylus">


@font-face {
  font-family: 'SourceHan'; /* 为字体定义一个名称 */
  src: url('/src/assets/SourceHanSansCN-Medium_1.woff') format('woff'); /* 指定字体文件的路径和格式 */
  /* 可以添加更多的格式和路径，以确保跨浏览器兼容性 */
}

* {
  font-family: 'SourceHan','PingFang SC' !important; /* 使用您定义的字体名称 */
}
.login-wap{
  width:100%;
  height:100%;
  position:fixed;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.60);
  z-index:2
}
.login {
  width: 440px;
  height: 546px;
  border-radius: 8px;
  border: 1px solid #878787;
  background: #fff;
  padding: 40px 40px 24px;
  box-sizing: border-box;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  // z-index: 2;
}
.close-icon {
  width:24px;
  height:24px;
  position: absolute;
  top: 12px;
  right: 12px;
  cursor: pointer;
}

.login-title {
  display: flex;
  justify-self: flex-start;
  align-items: center;
  color: #000;
  font-family: Source Han Sans CN;
  font-size: 30px;
  font-style: normal;
  font-weight: 500;
  line-height: 100%; /* 30px */
  margin-bottom: 24px;
}
.login-desc {
  display: flex;
  justify-self: flex-start;
  align-items: center;
  color: #000;
  font-family: Source Han Sans CN;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%; /* 30px */
  margin-bottom: 16px;
}
.login-phone-number.active{
  margin-bottom:40px;
}
.login-phone-number {
  margin-bottom: 24px;

  .login-phone-title {
    color: #000;
    font-family: Source Han Sans CN;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-bottom: 4px;
  }
  .login-phone-box{
    position relative;
  }
  .login-phone-icon{
    width:20px;
    height:20px;
    position:absolute;
    top:12px;
    right:12px;
    cursor:pointer;
  }

  .login-phone-input-box{
    position:relative;

    .login-phone-input-button{
      color: #17CE92;
      font-family: Source Code Pro;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 150%; /* 21px */
      position:absolute;
      top:12px;
      right:12px;
      cursor:pointer;
    }
    .login-error-phone-input-button{
      color: #17CE92;
      font-family: Source Code Pro;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 150%; /* 21px */
      position:absolute;
      top:12px;
      right:12px;
      cursor:pointer;
    }
  }

  .login-phone-input {
    width: 100%;
    height: 45px;
    border-radius: 4px;
    border: 0.6px solid rgba(0, 0, 0, 0.2);
    background: #fff;
    color: #000;
    font-family: Source Han Sans CN;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    box-sizing: border-box;
    padding-left: 8px;
  }
}
.login-button{
    width:100%;
    height:48px;
    border-radius: 4px;
    background: #AFAFAF;
    color: rgba(255, 255, 255, 0.50);
    font-family: Source Han Sans CN;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    display:flex;
    justify-content:center;
    align-items:center;
    cursor:pointer;
    margin-bottom:16px;
    margin-top:16px;
    overflow hidden
}
.login-button.active{
  background: #17CE92;
  color: #FFF;
}

.wx-login-button{
    width:100%;
    height:48px;
    border-radius: 4px;
    background: #17CE92;
    color: #FFF;
    font-family: Source Han Sans CN;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    display:flex;
    justify-content:center;
    align-items:center;
    cursor:pointer;
    margin-bottom:40px;

    .wx-login-button-icon{
      width:24px;
      height:24px;
      margin-right:8px;
    }
}
.login-text{
  color: #7D7D7D;
  font-family: Source Han Sans CN;
  font-size: 14px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  text-align:center;
}
.login-agreement-box{
  display:flex;
  justify-content:center;
  align-items:center;

}
.login-agreement{
  color: #17CE92;
  font-family: Source Han Sans CN;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-align:center;
  margin-top: 2px;
  text-decoration:none;
}
// 取消input的上下箭头
input::-webkit-inner-spin-button {
    -webkit-appearance: none !important;
  }

  input::-webkit-outer-spin-button {
    -webkit-appearance: none !important;
  }

  input[type="number"] {
    -moz-appearance: textfield;
  }
</style>
