import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import About from '../views/About.vue'
import gptDesc from '../views/gptDesc.vue'
import loginresult from '../views/loginresult.vue'



Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/about',
    name: 'About',
    component: About
  },
  {
    path: '/gptDesc',
    name: 'gptDesc',
    component: gptDesc
  },{
    path: '/loginresult',
    name: 'loginresult',
    component: loginresult
  }
]

const router = new VueRouter({
  mode:'history',
  routes
})

export default router
