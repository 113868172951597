import { apiMap } from '/api/config/apiMap'

import axios from 'axios';

axios.defaults.headers.post['Content-Type'] = 'application/json';
axios.defaults.withCredentials = true;

let request = function (param) {
  param = param || {};
  console.log(param.data)
  param.header = param.header || {};
  // localStorage.getItem('TOKEN') && (param.header.Authorization = 'basic ' + localStorage.getItem('TOKEN'));

  const promise = new Promise((resolve, reject) => {

    // if (!uni.getStorageSync('TOKEN') && !uni.getStorageSync('OPENID')) {
    // 	resolve({err: '没有获取到TOKEN'});
    // }
    this.$axios(
      {
        url: apiMap.host + param.url,
        data: param.data || {},
        method: param.method || 'POST',
        headers: {
          from: 1,
          version: "1.0.0",
        }
      }
    ).then((res) => {
      console.log(res);
    });
    axios({
      url: apiMap.host + param.url,
      params: param.data || {},
      method: param.method || 'POST',
      success: (res) => {
        console.log(res.data.status == 400 && res.data.errorCode == 10000)
        if (res.data.status == 400 && res.data.errorCode == 10000) {
          console.log('错误登陆')

        }
        if (res.data.code == 10303) {

        }

        if (~~res.statusCode === 200) {
          const info = {
            ...res.data
          }
          resolve(info)
        }
        else {
          console.log(res.data)
          // token失效重定向到登录页面，重新获取token  token fail
          if (res.data.message === 'token fail') {
            console.log('错误登陆')

          }

          // resolve({})
        }
      },
      fail: res => {
        console.log(res);
        const info = {
          ...res.data
        }
        resolve(info)
      }

    });
  }).catch(res => {

  })
  return promise;
}

export default request;