<template>
  <div class="login-box" ref="scrollmain">
    <div
      v-if="formattedString != ''"
      @click="navtolingqu"
      class="tips"
      v-html="formattedString"
    ></div>
    <div class="content-box">
      <div class="app-content">
        <div class="app-img-box">
          <img
            style="width: 88px; height: 88px; margin-right: 12px"
            :src="assistant.avatarUrl"
            :fit="'fill'"
          />
        </div>
        <div class="app-text-box">
          <div class="app-text-title">{{ assistant.name }}</div>
          <div class="app-text-desc">
            {{ assistant.describe }}
          </div>
          <div class="app-text-user">
            <div class="app-text-user-desc">开发者：</div>
            <img
              style="width: 16px; height: 16px; margin-right: 4px"
              :src="assistant.developerAvatar"
              :fit="'fill'"
            />
            <div class="app-text-user-name">{{ assistant.developerName }}</div>
          </div>
        </div>
      </div>
      <div class="app-desc">
        {{ assistant.prologue }}
      </div>
      <div class="example-list">
        <div class="example-title">你也可以直接问我</div>
        <div
          v-for="item in assistant.question"
          class="example-item"
          @click="chooseQuessionItem(item)"
        >
          {{ item }}
          <img
            style="width: 24px; height: 24px"
            :src="require('@/assets/example-icon.png')"
            :fit="'fill'"
          />
        </div>
      </div>
    </div>
    <div v-if="contentList.length > 0" class="content-box2">
      <div class="qa-list">
        <div class="qa-item" v-for="item in contentList">
          <div class="quesion-box">
            <div class="quesssion-avator">You</div>
            <div class="quesssion-text">
              {{ item.quesssion }}
            </div>
          </div>
          <div class="answer-box">
            <img
              style="width: 30px; height: 30px; margin-right: 12px"
              :src="assistant.avatarUrl"
              :fit="'fill'"
            />
            <div v-if="item.showdot" class="threedotloader">
              <div class="dot"></div>
              <div class="dot"></div>
              <div class="dot"></div>
            </div>
            <div v-else class="answer-text">
              {{ item.answer }}
              <div v-if="item.showbutton" class="answer-button-group">
                <div class="answer-button-item" @click="copy(item.answer)">
                  <img
                    class="answer-button-item-icon"
                    :src="require('@/assets/copy.png')"
                    :fit="'fill'"
                  />
                  复制
                </div>
                <div
                  class="answer-button-item"
                  :class="{ active: item.like == true }"
                  @click="like(item)"
                >
                  <img
                    v-if="item.like == true"
                    class="answer-button-item-icon"
                    :src="require('@/assets/like2.png')"
                    :fit="'fill'"
                  />
                  <img
                    v-else
                    class="answer-button-item-icon"
                    :src="require('@/assets/like.png')"
                    :fit="'fill'"
                  />
                  赞
                </div>
                <div
                  class="answer-button-item"
                  :class="{ disactive: item.dislike == true }"
                  @click="dislike(item)"
                >
                  <img
                    v-if="item.dislike == true"
                    class="answer-button-item-icon"
                    :src="require('@/assets/dislike2.png')"
                    :fit="'fill'"
                  />
                  <img
                    v-else
                    class="answer-button-item-icon"
                    :src="require('@/assets/dislike.png')"
                    :fit="'fill'"
                  />
                  不喜欢
                </div>
                <div
                  class="answer-button-item"
                  :class="{ active2: item.collect == true }"
                  @click="choosecollect(item)"
                >
                  <img
                    v-if="item.collect == true"
                    class="answer-button-item-icon"
                    :src="require('@/assets/collect2.png')"
                    :fit="'fill'"
                  />
                  <img
                    v-else
                    class="answer-button-item-icon"
                    :src="require('@/assets/collect.png')"
                    :fit="'fill'"
                  />
                  收藏
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="zhanwei" :class="{ active: showstop == true }"></div>
    <div class="input-box">
      <div v-if="showstop" class="stop-button">
        <div class="stop-button-content" @click="enterStop">停止生成</div>
      </div>
      <div class="input-button-group">
        <div
          @click="chosssegpt3"
          class="button-group-item"
          :class="{ active: this.checked == false }"
          style="margin-right: 16px"
        >
          <img
            v-if="this.checked == false"
            style="width: 14px; height: 14px; margin-right: 4px"
            :src="require('@/assets/star4.png')"
            :fit="'fill'"
          />
          <img
            v-else
            style="width: 14px; height: 14px; margin-right: 4px"
            :src="require('@/assets/star.png')"
            :fit="'fill'"
          />
          GPT-3.5 Turbo
        </div>
        <div
          @click="chosssegpt4"
          class="button-group-item"
          :class="{ active: this.checked == true }"
        >
          <img
            v-if="this.checked == true"
            style="width: 14px; height: 14px; margin-right: 4px"
            :src="require('@/assets/star3.png')"
            :fit="'fill'"
          />
          <img
            v-else
            style="width: 14px; height: 14px; margin-right: 4px"
            :src="require('@/assets/star2.png')"
            :fit="'fill'"
          />
          GPT-4 Turbo
        </div>
      </div>
      <div class="app-input-box">
        <textarea
          class="app-input"
          v-model="input"
          :style="{ height: computedHeight, maxHeight: '220px' }"
          @input="adjustTextareaHeight"
          ref="textarea"
          placeholder=""
          @focus="inputfocus"
        >
        </textarea>
        <div
          v-if="this.input.trim() != ''"
          @click="sureSendWay"
          class="send-button active"
        >
          发送
        </div>
        <div v-else class="send-button">发送</div>
      </div>
    </div>
    <div v-if="showerrormsg" class="errormsg">
      {{ errormsg }}
    </div>
    <div v-if="showMask" class="mask"></div>

    <div v-if="showToast" class="toast">
      <div class="toast-title">您的 VIP 已经结束</div>
      <div class="toast-desc">开通 VIP 不限量对话</div>
      <div class="toast-button" @click="hideToast">我知道了</div>
      <img
        class="toast-close"
        @click="hideToast"
        :src="require('@/assets/toaseClose.png')"
        alt=""
      />
    </div>
    <div v-if="showToast2" class="toast">
      <div class="toast-title">
        您的 SVIP 今日 GPT-4 Turbo 对话：25条 已经用完
      </div>
      <div class="toast-desc">普通对话：不限量</div>
      <div class="toast-button" @click="hideToast2">我知道了</div>
      <img
        class="toast-close"
        @click="hideToast"
        :src="require('@/assets/toaseClose.png')"
        alt=""
      />
    </div>
    <div v-if="showToast3" class="toast">
      <div class="toast-title">您的免费对话次数已经用完</div>
      <div class="toast-desc">开通 VIP 不限量对话</div>
      <div class="toast-button" @click="navtoxiazai">点击这里获取正式 VIP</div>
      <img
        class="toast-close"
        @click="hideToast"
        :src="require('@/assets/toaseClose.png')"
        alt=""
      />
    </div>
    <div v-if="showToast4" class="toast">
      <div class="toast-title">您的 SVIP 已经结束</div>
      <div class="toast-desc">
        <p>开通 SVIP</p>
        <p>体验更强大的 GPT-4 Turbo</p>
      </div>
      <div class="toast-button" @click="navtoxiazai">点击这里获取正式 VIP</div>
      <img
        class="toast-close"
        @click="hideToast"
        :src="require('@/assets/toaseClose.png')"
        alt=""
      />
    </div>
    <div v-if="showToast5" class="toast">
      <div class="toast-title">
        您的 SVIP 今日 GPT-4 Turbo 对话：25条 已经用完
      </div>
      <div class="toast-desc">普通对话：不限量</div>
      <div class="toast-button" @click="hideToast">我知道了</div>
      <img
        class="toast-close"
        @click="hideToast"
        :src="require('@/assets/toaseClose.png')"
        alt=""
      />
    </div>
  </div>
</template>

<script>
import md5 from "md5";
import hljs from "highlight.js";
import { appMsg, apiMap } from "/api/config/apiMap.js";

export default {
  data() {
    return {
      input: "",
      assistantId: "",
      showstop: false,
      minHeight: 42,
      maxHeight: 232,
      noContent: true,
      canUse: false,
      nosvip: false,
      checked: false,
      userinfo: {},
      showerrormsg: false,
      errormsg: "",
      showToast: false,
      showToast2: false,
      showToast3: false,
      showToast4: false,
      showToast5: false,
      showMask: false,
      contentList: [],
      identification: "",
      invitorTiantiId: "",
      loading: {},
      visitorId: "",
      token: "",
      wxcode: "",
      computedHeight: "42px",
      assistant: {},
    };
  },
  computed: {
    formattedString() {
      if (this.userinfo.vipTips) {
        if (this.userinfo.vipType > 0) {
          let text = this.userinfo.vipTips.replace(/&hairsp;/g, " ");
          text = text.replace("{", "");
          text = text.replace("}", " ");
          return text;
        } else {
          let text = this.userinfo.vipTips.replace(/&hairsp;/g, " ");
          text = text.replace("{", "");
          text = text.replace("}", " ");
          text = text.replace("0", this.userinfo.count);
          return text;
        }
      } else {
        return "普通用户免费享受对话：剩余 5次/天   点击获取 VIP";
      }
    },
  },
  mounted() {
    let that = this;

    const assistantId = this.$route.query.assistantId;
    // const assistantId = 10018;
    // 获取 identification 参数的值
    const invitorTiantiId = this.$route.query.identification;

    const wxcode = this.$route.query.code;

    console.log(assistantId, invitorTiantiId);

    if (assistantId && invitorTiantiId) {
      this.assistantId = assistantId;
      this.invitorTiantiId = invitorTiantiId;
      localStorage.setItem("assistantId", assistantId);
      localStorage.setItem("invitorTiantiId", invitorTiantiId);
    } else {
      this.assistantId = localStorage.getItem("assistantId");
      this.invitorTiantiId = localStorage.getItem("invitorTiantiId");
    }

    if (wxcode) {
      this.wxcode = wxcode;
      localStorage.setItem("wxcode", wxcode);
    } else {
      this.wxcode = localStorage.getItem("wxcode");
    }

    const key = appMsg.http;
    console.log(key, `${this.assistantId}${key}`);
    const sign = md5(`${this.assistantId}${key}`);
    this.$axios({
      method: "get", //指定请求方式
      url: apiMap.host + "/api/app/v1/assistant/info", //请求接口（相对接口，后面会介绍到）
      // url: "/re/api/web/v1/chat/session/list", //请求接口（相对接口，后面会介绍到）
      headers: {
        from: 1,
        version: "1.1.0",
        serial: new Date().getTime() + "",
      },
      withCredentials: true,
      params: {
        id: this.assistantId,
        sign: sign,
      },
    }).then((res) => {
      this.assistant = res.data.data;
    });

    const token = localStorage.getItem("H5token");

    if (token) {
      this.loading = this.$loading({
        lock: true,
        text: "加载中...",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });

      const identification = localStorage.getItem("H5identification");
      const sign2 = md5(identification + appMsg.http);
      const visitorId = localStorage.getItem("H5visitorId");

      that
        .$axios({
          method: "GET", //指定请求方式
          url: apiMap.host + "/api/app/v1/user/info", //请求接口（相对接口，后面会介绍到）
          headers: {
            from: 1,
            version: "1.1.0",
            visitorId: visitorId,
            serial: new Date().getTime() + "",
          },
          params: {
            identification: identification, // 手机号
            sign: sign2,
          },
        })
        .then((res) => {
          console.log(res.data);
          if (res.data.errorCode == 10000 || res.data.status == 400) {
            // 出错
            return;
          }
          localStorage.setItem("H5userinfo", JSON.stringify(res.data.data));
          if (res.data.data.vipType > 0) {
            this.loginSuccess(res.data.data);
          } else {
            if (res.data.data.count > 0) {
              this.loginSuccess(res.data.data);
            } else {
              this.loginError(res.data.data);
            }
          }
          let key2 = appMsg.http;
          let identification = localStorage.getItem("H5identification");
          let assistantId = this.assistantId;
          let page = 0;
          let size = 20;
          let time = new Date().getTime() + "";
          let sign2 = md5(
            `${identification}${page}${size}${assistantId}${key2}`
          );
          console.log(`${identification}${page}${size}${assistantId}${key2}`);
          console.log(sign2);
          this.$axios({
            method: "get", //指定请求方式
            url: apiMap.host + "/api/app/v2/chat/lists", //请求接口（相对接口，后面会介绍到）
            // url: "/re/api/web/v1/chat/session/list", //请求接口（相对接口，后面会介绍到）
            headers: {
              from: 1,
              version: "1.1.0",
              serial: time,
              Authorization: "basic " + localStorage.getItem("H5token"),
              sessionid: token,
              visitorId: localStorage.getItem("H5visitorId"),
            },
            params: {
              assistantId: assistantId,
              page: page,
              sign: sign2,
              size: size,
            },
          }).then((res) => {
            console.log(res.data);
          });
        });

      // this.creatWebSocket()
    } else if (wxcode) {
      this.loading = this.$loading({
        lock: true,
        text: "加载中...",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });

      this.$axios({
        method: "post", //指定请求方式
        url: apiMap.host + "/api/h5/v1/user/invite/h5/wx", //请求接口（相对接口，后面会介绍到）
        // url: "/re/api/web/v1/chat/session/list", //请求接口（相对接口，后面会介绍到）
        headers: {
          from: 1,
          version: "1.1.0",
          serial: new Date().getTime() + "",
        },
        withCredentials: true,
        data: {
          code: wxcode,
          invitorId: this.invitorTiantiId,
        },
      }).then((res) => {
        let resValue = res.data.data;
        this.identification = resValue.identification;
        this.visitorId = resValue.visitorId;
        this.token = resValue.token;

        localStorage.setItem("H5visitorId", resValue.visitorId);
        localStorage.setItem("H5identification", resValue.identification);
        localStorage.setItem("H5token", resValue.token);
        this.getuserinfo();
      });
    }
  },
  methods: {
    chosssegpt4() {
      if (!this.canUse) {
        this.login();
        return;
      }
      if (this.userinfo.vipType < 2) {
        this.$router.push(`/gptDesc`);
        return;
      } else {
        if (this.userinfo.svip.count < 1) {
          this.showToast5 = true;
          this.showMask = true;
          return;
        }
      }

      this.checked = true;
    },
    chosssegpt3() {
      if (!this.canUse) {
        this.login();
        return;
      }
      this.checked = false;
    },
    like(param) {
      console.log(param);
      param.like = true;
      param.dislike = false;
      this.$nextTick();
    },
    dislike(param) {
      param.like = false;
      param.dislike = true;
    },
    choosecollect(param) {
      console.log(param);
      //收藏
      // param.collect = !param.collect;
      const identification = localStorage.getItem("H5identification");
      const visitorId = localStorage.getItem("H5visitorId");

      let that = this;
      let status = param.collect == false ? 1 : 0;
      const sign2 = md5('1' + identification + param.orgSerialNo + status + appMsg.http);
      console.log('1',identification,param.orgSerialNo,status,appMsg.http);
      const token = localStorage.getItem("H5token");
      that.$axios({
          method: "POST", //指定请求方式
          url: apiMap.host + "/api/app/v1/user/store/chat", //请求接口（相对接口，后面会介绍到）
          headers: {
            from: 1,
            version: "1.1.0",
            visitorId: visitorId,
            serial: new Date().getTime() + "",
            Authorization: "basic " + localStorage.getItem("H5token"),
            sessionid: token,
          },
          data: {
            identification: identification, // 手机号
            sign: sign2,
            type: 1,
            id: param.orgSerialNo,
            assistantId: this.assistantId,
            status:  param.collect == false ? 1 : 0
          },
        }).then(res=>{
          console.log(res.data);
          param.collect = !param.collect;
        })
    },
    getuserinfo() {
      let that = this;
      const identification = localStorage.getItem("H5identification");
      const sign2 = md5(identification + appMsg.http);
      const visitorId = localStorage.getItem("H5visitorId");

      that
        .$axios({
          method: "GET", //指定请求方式
          url: apiMap.host + "/api/app/v1/user/info", //请求接口（相对接口，后面会介绍到）
          headers: {
            from: 1,
            version: "1.1.0",
            visitorId: visitorId,
            serial: new Date().getTime() + "",
          },
          params: {
            identification: identification, // 手机号
            sign: sign2,
          },
        })
        .then((res) => {
          console.log(res.data);
          if (res.data.errorCode == 10000 || res.data.status == 400) {
            // 出错
            return;
          }
          localStorage.setItem("H5userinfo", JSON.stringify(res.data.data));
          if (res.data.data.vipType > 0) {
            this.loginSuccess(res.data.data);
          } else {
            if (res.data.data.count > 0) {
              this.loginSuccess(res.data.data);
            } else {
              this.loginError(res.data.data);
            }
          }
          let key2 = appMsg.http;
          let identification = localStorage.getItem("H5identification");
          let assistantId = this.assistantId;
          let page = 0;
          let size = 20;
          let time = new Date().getTime() + "";
          let sign2 = md5(
            `${identification}${page}${size}${assistantId}${key2}`
          );
          console.log(`${identification}${page}${size}${assistantId}${key2}`);
          console.log(sign2);
          this.$axios({
            method: "get", //指定请求方式
            url: apiMap.host + "/api/app/v2/chat/lists", //请求接口（相对接口，后面会介绍到）
            // url: "/re/api/web/v1/chat/session/list", //请求接口（相对接口，后面会介绍到）
            headers: {
              from: 1,
              version: "1.1.0",
              serial: time,
              Authorization: "basic " + localStorage.getItem("H5token"),
              sessionid: localStorage.getItem("H5token"),
              visitorId: localStorage.getItem("H5visitorId"),
            },
            params: {
              assistantId: assistantId,
              page: page,
              sign: sign2,
              size: size,
            },
          }).then((res) => {
            console.log(res.data);
          });
        });
    },
    navtolingqu() {
      const token = localStorage.getItem("H5token");

      if (token) {
        console.log("已登陆");
        this.navtoxiazai();
      } else {
        let assistantId = localStorage.getItem("assistantId");
        let invitorTiantiId = localStorage.getItem("invitorTiantiId");
        const redirectUri =
          "https://chat.airmx.com/loginresult?invitorTiantiId=" +
          invitorTiantiId;
        // const redirectUri = `https://chat.airmx.com/about?identification=${invitorTiantiId}&assistantId=${assistantId}`;
        let linkurl = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=wx0ae265d67b3aa5bb&redirect_uri=${encodeURIComponent(
          redirectUri
        )}&response_type=code&scope=snsapi_userinfo&state=STATE#wechat_redirect`;
        console.log(linkurl);
        window.open(linkurl, "_self");
        return;
      }
    },
    login() {
      let assistantId = localStorage.getItem("assistantId");
      let invitorTiantiId = localStorage.getItem("invitorTiantiId");
      const redirectUri = `https://chat.airmx.com/about?identification=${invitorTiantiId}&assistantId=${assistantId}`;
      let linkurl = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=wx0ae265d67b3aa5bb&redirect_uri=${encodeURIComponent(
        redirectUri
      )}&response_type=code&scope=snsapi_userinfo&state=STATE#wechat_redirect`;
      console.log(linkurl);
      window.open(linkurl, "_self");

      // window.location.href = linkurl;
    },
    inputfocus(event) {
      if (!this.canUse) {
        this.login();
        event.preventDefault();
        event.stopPropagation();
      }
    },
    sureSendWay(param) {
      if (this.canUse == false) {
        this.showLogin = true;
        this.showstop = false;
        return;
      }
      if (this.nosvip == true) {
        this.errorshowToast();
        return;
      }
      if (this.input.trim() == "") {
        // this.phoneError("不能输入空值")
        this.showstop = false;
        return;
      }
      if (this.userinfo.vipType == 0) {
        console.log(this.userinfo, "无vip");
        if (this.userinfo.count < 1) {
          if(this.userinfo.svip){
            let svipendtime = this.userinfo.svip.end;
            let svipcurrentTimestamp = new Date().getTime();
            if(this.userinfo.vip){
              let vipendtime = this.userinfo.svip.end;
              if(svipendtime >= vipendtime){
                this.showToast4 = true;
                this.showMask = true;
                return
              }else{
                this.showToast = true;
                this.showMask = true;
                return
              }
            }else{
              this.showToast4 = true;
              this.showMask = true;
              return
            }
          }else if(this.userinfo.vip){
            this.showToast = true;
            this.showMask = true;
            return
          }else{
            this.showToast3 = true;
            this.showMask = true;
            return
          }
        }
        // 无vip
      } else if (this.userinfo.vipType == 2) {
        // 有svip
        let endtime = this.userinfo.svip.end;
        let currentTimestamp = new Date().getTime();

        console.log(this.userinfo, "有svip");
        // 有svip
        if (endtime < currentTimestamp) {
          if (this.userinfo.vip) {
            this.checked = false;
            this.phoneError("svip已到期");
          } else {
            // svip到期
            this.showToast4 = true;
            this.showMask = true;
            return;
          }
        } else {
          console.log(this.userinfo.svip.count);
          if (this.userinfo.svip.count < 1 && this.checked == true) {
            this.showToast5 = true;
            this.showMask = true;
            this.checked = false;
            return;
          } else {
            if (this.checked == true) {
              this.userinfo.svip.count = this.userinfo.svip.count - 1;
            }
          }
        }
      } else if (this.userinfo.vipType == 1) {
        console.log(this.userinfo, "有vip");
        let endtime = this.userinfo.vip.end;
        let currentTimestamp = new Date().getTime();
        if (endtime < currentTimestamp) {
          // vip到期
          this.showToast = true;
          this.showMask = true;
          return;
        }
      }

      let that = this;
      const identification = localStorage.getItem("H5identification");
      const visitorId = localStorage.getItem("H5visitorId");
      const sessionId = this.sessionId;
      const name = this.input.slice(0, 20);
      const key = appMsg.websocket;
      const sign = md5(`${identification}${sessionId}${name}${key}`);
      const sign2 = md5(identification + appMsg.websocket);

      if (
        that.userinfo.svip &&
        that.userinfo.svip.end <= new Date().getTime()
      ) {
        that
          .$axios({
            method: "GET", //指定请求方式
            url: apiMap.host + "/api/app/v1/user/info", //请求接口（相对接口，后面会介绍到）
            headers: {
              from: 1,
              version: "1.1.0",
              visitorId: visitorId,
              serial: new Date().getTime() + "",
            },
            params: {
              identification: identification, // 手机号
              sign: sign2,
            },
          })
          .then((res2) => {
            if (res2.data.errorCode == 10000 || res2.data.status == 400) {
              that.dialogFormVisible3 = true;
              that.loginout();
              return;
            }
            that.loading.close();
            localStorage.setItem("userinfo", JSON.stringify(res2.data.data));
            that.loginError(res2.data.data);
          });
      }
      this.caninput = false;
      this.showstop = true;

      let time = new Date().getTime();
      let serialNo = this.userinfo.visitorId;
      let message = {
        command: "app-quesion",
        serialNo: `${time}`,
        data: this.input,
        model: this.checked ? 2 : 1,
        type: 1,
        assistantId: this.assistantId - 0,
        needPrompt: 0,
        style: 1,
      };

      this.contentList.push({
        quesssion: "",
        answer: "",
        showdot: true,
        showbutton: false,
        think: [],
        orgSerialNo: null,
        like: false,
        dislike: false,
        collect: false,
      });
      this.noContent = false;
      this.contentList[this.contentList.length - 1].quesssion = this.input;
      this.input = "";
      if (this.userinfo.vipType < 1) {
        this.userinfo.count = this.userinfo.count - 1;
        localStorage.setItem("userinfo", JSON.stringify(this.userinfo));
      }

      if (this.gptNumber == 0) {
        this.checked = false;
      }
      this.websocket.send(JSON.stringify(message));
      const textarea = this.$refs.textarea;
      textarea.style.height = "42px"; // 先重置高度以便重新计算
      setTimeout(() => {
        this.$refs.scrollmain.scrollTop = this.$refs.scrollmain.scrollHeight
          ? this.$refs.scrollmain.scrollHeight
          : 0;
      }, 100);
    },
    loginout() {
      this.noContent = true;
      localStorage.removeItem("token");
      this.gptNumber = 0;
      this.contentList = [];
      this.userinfo = {};
      this.canUse = false;
    },
    adjustTextareaHeight() {
      console.log("触发了自动修改");
      const textarea = this.$refs.textarea;
      textarea.style.height = "42px"; // 先重置高度以便重新计算
      textarea.style.height = textarea.scrollHeight + "px"; // 根据内容高度设置高度
    },
    errorshowToast() {
      this.showToast3 = true;
      this.showMask = true;
    },
    errorshowToast2() {
      this.showToast = true;
      this.showMask = true;
    },
    hideToast() {
      this.showToast = false;
      this.showToast2 = false;
      this.showToast3 = false;
      this.showToast4 = false;
      this.showToast5 = false;
      this.showMask = false;
    },
    hideToast2() {
      this.showToast2 = false;
      this.showMask = false;
    },
    navtoxiazai() {
      let linkurl = "https://quip.weixuanpro.cn/h5/wxdownload.html";
      window.open(linkurl, "_self");
    },
    loginSuccess(param) {
      this.canUse = true;
      this.nosvip = false;
      this.userinfo = param;
      this.creatWebSocket();
    },
    loginError(param) {
      this.loading.close();
      this.canUse = true;
      this.nosvip = true;
      this.userinfo = param;
    },

    changeinput(event) {
      let e = window.event || arguments[0];
      event.preventDefault();

      if (e.key == "Enter" && e.code == "Enter" && e.keyCode == 13) {
        if (!e.metaKey) {
          // e.metaKey == true证明是ctrl+enter
          e.returnValue = false;
          this.showstop = true;
          this.sureSendWay();
          return false;
        } else {
          this.question = this.question + "\n";
        }
      }
    },
    chooseQuessionItem(item) {
      if (!this.canUse) {
        this.login();
        return;
      }
      if (this.nosvip == true) {
        this.errorshowToast();
        return;
      }
      this.input = item;
      this.$nextTick(() => {
        this.adjustTextareaHeight();
      });
    },
    phoneError(param) {
      this.closetoast();
      this.showerrormsg = true;
      this.errormsg = param;
      setTimeout(() => {
        this.closetoast();
      }, 3000);
    },
    closetoast() {
      this.showerrormsg = false;
      this.errormsg = "";
    },
    enterStop() {
      let time = new Date().getTime();
      let serialNo = this.userinfo.visitorId;
      let message = {
        command: "app-stop",
        serialNo: `${serialNo}${time}`,
      };
      if (this.contentList[this.contentList.length - 1].answer == "") {
        this.contentList[this.contentList.length - 1].answer = "已停止生成";
        this.contentList[this.contentList.length - 1].showdot = false;
      }
      this.websocket.close();
      this.creatWebSocket();
    },
    copy(text) {
      const inputElement = document.createElement("textarea");
      inputElement.value = text;
      document.body.appendChild(inputElement);
      inputElement.select();
      document.execCommand("copy");
      document.body.removeChild(inputElement);
      this.phoneError("文案已复制");
    },
    creatWebSocket(param, serialnum) {
      let that = this;
      let loadingg = this.loading;
      let serial;
      const visiterId = localStorage.getItem("H5visitorId");
      const identification = localStorage.getItem("H5identification");

      let sessionId;
      const key = appMsg.h5socket;

      if (param) {
        sessionId = param;
      } else {
        sessionId = new Date().getTime();
      }

      if (serialnum) {
        serial = serialnum;
      } else {
        serial = new Date().getTime();
      }

      this.sessionId = sessionId;

      const token = md5(
        `${serial}${visiterId}${identification}${sessionId}${key}`
      );

      const wsUrl = `wss://ws.airmx.com/quip/chat?token=${token}&serial=${serial}&visiterId=${visiterId}&identification=${identification}&version=1.5.0&from=1&sessionId=${sessionId}`;

      this.websocket = new WebSocket(wsUrl);

      this.websocket.addEventListener("open", () => {
        console.log("WebSocket连接已建立");
        // this.loading.close();
        // alert("WebSocket连接已建立")
        loadingg.close();
        // console.log(this.websocket);
      });

      this.websocket.onmessage = (event) => {
        // console.log("Received message:", event.data);
        const targetString = "quip-stop";
        const targetString2 = "quip-quesion";
        const targetString3 = "quip-refuse";
        const stopString = "app-stop";

        if (event.data.includes(targetString3)) {
          this.dialogFormVisible3 = true;
          this.loginout();
        } else if (event.data.includes(targetString)) {
          this.showstop = false;
          this.caninput = true;
          this.contentList[this.contentList.length - 1].showbutton = true;
          const inputString = event.data;
          const lastIndexOfHyphen = inputString.lastIndexOf("-");
          const secondLastIndexOfHyphen = inputString.lastIndexOf(
            "-",
            lastIndexOfHyphen - 1
          );
          const startIndex2 = inputString.lastIndexOf("_");

          if (
            secondLastIndexOfHyphen !== -1 &&
            lastIndexOfHyphen !== -1 &&
            lastIndexOfHyphen > secondLastIndexOfHyphen
          ) {
            const variablePart = inputString.substring(
              secondLastIndexOfHyphen + 1,
              lastIndexOfHyphen
            );
            this.contentList[this.contentList.length - 1].orgSerialNo =
              variablePart;
          } else {
            console.log("未找到匹配的数据");
          }
        } else if (event.data.includes(targetString2)) {
          const regex = /\[([^[\]]*)\]/g;
          const regex2 = /(\d+)/g;
          const matches = event.data.match(regex);
          const matches2 = event.data.match(regex2);
          const number = parseInt(matches2[0]);
          const arrays = matches.map((match) => JSON.parse(match));
          this.contentList.forEach((element) => {
            if (element.orgSerialNo == number) {
              element.think = arrays[0];
              setTimeout(() => {
                this.$refs.scrollmain.scrollTop = this.$refs.scrollmain
                  .scrollHeight
                  ? this.$refs.scrollmain.scrollHeight
                  : 0;
              }, 300);
            }
          });
        } else {
          console.log(
            that.$refs.scrollmain.scrollTop,
            that.$refs.scrollmain.scrollHeight
          );
          this.contentList[this.contentList.length - 1].showdot = false;
          this.contentList[this.contentList.length - 1].answer =
            this.contentList[this.contentList.length - 1].answer + event.data;
          this.$refs.scrollmain.scrollTop = this.$refs.scrollmain.scrollHeight
            ? this.$refs.scrollmain.scrollHeight
            : 0;
          setTimeout(() => {
            that.$refs.scrollmain.scrollTop = that.$refs.scrollmain.scrollHeight
              ? that.$refs.scrollmain.scrollHeight
              : 0;
          }, 300);

          this.showstop = true;
          this.caninput = false;
        }
        // that.contentList[this.contentList.length - 1].answer = event.data
        // 处理服务器返回的消息
      };

      //监听WebSocket关闭事件
      this.websocket.addEventListener("close", () => {
        // console.log("WebSocket连接已关闭");
        this.showstop = false;
        this.caninput = true;
        this.canUse = true;
        if (this.handClose) {
          this.handClose = false;
          return;
        } else if (this.chooseSessionId) {
          this.creatWebSocket(this.chooseSessionId);
        } else {
          this.creatWebSocket();
        }
        // 在这里执行断开连接后的操作，例如隐藏停止生成按钮
      });

      this.websocket.addEventListener("error", (error) => {
        // this.phoneError("当前网络异常，请稍后重试");
        // console.error("WebSocket错误:", error);
      });
    },
  },
};
</script>

<style scoped lang="stylus">

*{  -webkit-overflow-scrolling: touch;}
.login-box{
  width:100%;
  height:100vh;
  background-color: #202123;
  position: relative;
  // overflow: hidden;
  overflow: scroll;
}
.title-box{
  width:100%;
  height:54px;
  background-color:red;
}
.tips{
  width:100%;
  height:36px;
  background: #3C3D41;
  display:flex;
  justify-content:center;
  align-items: center;
  color: #D77F19;
  text-align: center;
  font-family: Source Han Sans CN;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 166.667% */
  position:fixed;
  top:0;
  left:0;
  z-index:3;
}
.content-box{
  width:100%;
  height:auto;
  overflow: scroll;
  background-color: #202123;
  padding: 0px 15px;
  box-sizing:border-box;
  padding-top:36px;
}
.content-box.active{
  padding-top:0px;
}
.content-box2{
  width:100%;
  height:auto;
  overflow: scroll;
  background-color: #202123;
  padding: 0px;
  box-sizing:border-box;
}
.zhanwei{
  width:100%;
  height:133px;
}
.zhanwei.active{
  height:181px;
}
.app-text-box{
  flex-grow: 1;
  // white-space: nowrap; /* 防止文本换行 */
  overflow: hidden;
  text-overflow: ellipsis; /* 显示省略号 */
}
.app-content{
  width:100%;
  height:auto;
  display:flex;
  justify-content:flex-start;
  align-items: flex-start;
  padding: 16px 0;
  box-sizing:border-box;
}
.app-text-title{
  color: #FFF;
  font-family: Source Han Sans CN;
  font-size: 22px;
  font-style: normal;
  font-weight: 500;
  line-height: 100%; /* 22px */
  margin-bottom:8px;
  white-space: nowrap; /* 不换行 */
  overflow: hidden; /* 隐藏溢出部分 */
  text-overflow: ellipsis; /* 显示省略号 */
}
.app-text-desc{
  height:36px;
  color: rgba(255, 255, 255, 0.70);
  font-family: Source Han Sans CN;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 18px */
  margin-bottom:8px;
  display: -webkit-box; /* 兼容WebKit浏览器 */
  -webkit-line-clamp: 2; /* 限制文本为两行 */
  overflow: hidden;
  text-overflow: ellipsis; /* 显示省略号 */
  -webkit-box-orient: vertical;
}
.app-text-user{
  display:flex;
  justify-content:flex-start;
  align-items: center;
  color: rgba(255, 255, 255, 0.70);
  font-family: Source Han Sans CN;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 18px */
  margin-right:4px;
}
.app-text-user-name{
  color: #FFF;
  font-family: Source Han Sans CN;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 18px */
}
.example-title{
  width:100%;
  height:30px;
  color: #FFF;
  font-family: Source Han Sans CN;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 160%; /* 22.4px */
  box-sizing:border-box;
  padding:4px 0;
  margin-top: 8px;
}
.example-item{
  width:100%;
  height:auto;
  box-sizing:border-box;
  padding:8px;
  margin-top: 8px;
  border-radius: 8px;
  background: #363739;
  color: rgba(255, 255, 255, 0.70);
  font-family: Source Han Sans CN;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 160%; /* 22.4px */
  display:flex;
  justify-content:space-between;
  align-items:center;
  cursor pointer;
}
.app-input-box{
  display:flex;
  justify-content:space-between;
  align-items:center;
  padding:8px;
  box-sizing:border-box;
  padding-bottom:34px;
}
.input-box{
  position:fixed;
  bottom:0px;
  left:0;
  width:100%;
  box-sizing:border-box;
  background-color:#202123;
  border-top:1px solid #2F2F2F;
  z-index: 9;
}
.stop-button{
  display:flex;
  justify-content:center;
  align-items:center;
  width:100%;
  height:auto;
  padding:8px;
  box-sizing:border-box;
}
.stop-button-content{
  display: flex;
  width: 96px;
  height: 32px;
  justify-content: center;
  align-items: center;
  border-radius: 50px;
  background: #444654;
  color: rgba(255, 255, 255, 0.90);
  text-align: center;
  font-family: Source Han Sans CN;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 100%; /* 14px */
}
.input-button-group{
  display:flex;
  justify-content:flex-start;
  align-items:center;
  width:100%;
  height:auto;
  padding:8px;
  box-sizing:border-box;
}
.button-group-item{
  display:flex;
  width: 148px;
  height: 32px;
  justify-content: center;
  align-items: center;
  font-family: Source Han Sans CN;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 142.857% */
  color: #FFF;
  border-radius: 8px;
  background: rgba(255, 255, 255, 0.10);
  cursor pointer;
}
.button-group-item.active{
  background: #1FE36E;
}
.app-input{
  width:100%;
  font-size: 16px !important;
  color: #FFF;
  font-family: Source Han Sans CN;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 160%; /* 22.4px */
  background-color:rgba(255, 255, 255, 0.10);
  padding:8px !important;
  border:0 !important;
  box-sizing:border-box;
  resize:none;
  border-radius: 4px;
}
.app-input:focus{
  border:0 !important;
  outline:none;
}
.send-button{
  width 72px;
  height:40px;
  border-radius: 4px;
  background: #40414E;
  color: rgba(255, 255, 255, 0.50);
  font-family: PingFang SC;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 40px; /* 142.857% */
  text-align:center;
  margin-left:8px;
}
.send-button.active{
  background: #17CE92;
  color: #FFF;
}
.app-desc{
  color: rgba(255, 255, 255, 0.70);
  font-family: Source Han Sans CN;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 21px */
  border-bottom:1px solid #363739;
  padding-bottom:16px;
  white-space:pre-line;
}
.quesion-box{
  width:100%;
  height:auto;
  padding: 15px;
  box-sizing:border-box;
  display:flex;
  justify-content: flex-start;
  align-items: flex-start;
  border-bottom: 0.5px solid #2A2B32;
  background: #202123;
  // flex-wrap:wrap;
}
.quesssion-avator{
  width: 30px;
  height: 30px;
  border-radius: 2px;
  background: #E7A03B;
  display:flex;
  justify-content: center;
  align-items: center;
  color: #FFF;
  text-align: center;
  font-family: PingFang SC;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 100%; /* 12px */
  margin-right:12px;
}
p{
  margin:0;
}
.quesssion-text{
  flex:1;
  color: rgba(255, 255, 255, 0.70);
  /* 正文 32 160% */
  font-family: Source Han Sans CN;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 160%; /* 25.6px */
}
.answer-box{
  width:100%;
  height:auto;
  padding: 15px;
  box-sizing:border-box;
  display:flex;
  justify-content: flex-start;
  align-items: flex-start;
  border-bottom: 0.5px solid #2A2B32;
  background: #2D2E31;
  // flex-wrap:wrap;
}
.answer-avator{
  width: 30px;
  height: 30px;
  border-radius: 2px;
  background: #E7A03B;
  display:flex;
  justify-content: center;
  align-items: center;
  color: #FFF;
  text-align: center;
  font-family: PingFang SC;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 100%; /* 12px */
  margin-right:12px;
}
.answer-text{
  flex:1;
  color: rgba(255, 255, 255, 0.70);
  /* 正文 32 160% */
  font-family: Source Han Sans CN;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 160%; /* 25.6px */
  white-space:pre-line;
}
.answer-button-group{
  display:flex;
  justify-content:flex-start;
  align-items:center;
  margin-top:16px;
}
.answer-button-item{
  padding: 2px 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 33px;
  border: 0.5px solid #59596C;
  color: #ACACBE;
  font-family: 'SourceHan';
  font-size: 12px;
  font-style: normal;
  font-weight: 350;
  line-height: 150%; /* 18px */
  margin-right:12px;
  cursor:pointer;

  .answer-button-item-icon{
    width: 12px;
    height: 12px;
    margin-right:2px;
  }
}
.errormsg{
  position:fixed;
  top:40%;
  left:50%;
  transform:translate(-50%,-50%);

  display: flex;
  padding: 8px 24px;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  background: rgba(255, 255, 255, 0.70);
  backdrop-filter: blur(2px);
  color: #202123;
  text-align: center;
  font-family: 'SourceHan';
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 21px; /* 150% */
  z-index 99;
}
.mask{
  position:fixed;
  top:0;
  left:0;
  z-index 90;
  width:100%;
  height:100%;
  background: rgba(0, 0, 0, 0.80);
}
.toast{
  position:fixed;
  top:45%;
  left:50%;
  transform:translate(-50%,-50%);
  z-index 99;
  padding: 40px 18px 24px 18px;
  border-radius: 8px;
  background: #2B2F3A;

  .toast-title{
    width:253px;
    color: rgba(255, 255, 255, 0.50);
    text-align: center;
    font-family: Source Han Sans CN;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%; /* 21px */
    margin-bottom:12px;
  }
  .toast-desc{
    color: #FFF;
    text-align: center;
    font-family: Source Han Sans CN;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%; /* 27px */
    margin-bottom:32px;
  }
  .toast-close{
    width: 24px;
    height: 24px;
    position:absolute;
    top:8px;
    right:8px;
  }
  .toast-button{
    display: flex;
    width: 208px;
    padding: 12px 24px;
    justify-content: center;
    align-items: center;
    border-radius: 62px;
    border: 1px solid #17CE92;
    background: linear-gradient(128deg, #1FE36E 21.74%, #17CE92 78.26%);
    color: #FFF;
    text-align: center;
    font-family: Source Han Sans CN;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%;  /* 24px */
    cursor: pointer;
  }
}
.answer-button-item.active{
  border: 0.5px solid #437B69;
  background: rgba(23, 206, 146, 0.10);
  color: #17CE92;
}
.answer-button-item.disactive{
  border: 0.5px solid #95473C;
  background: rgba(219, 68, 47, 0.10);
  color: #DB442F;
}
.answer-button-item.active2{
  border: 0.5px solid #9D8841;
  background: rgba(255, 195, 0, 0.10);
  color: #FFC300;
}
.threedotloader {
      // margin: 0 auto;
      width: 30px;
      height: 30px;
    }
    .threedotloader .dot {
      width: 6px;
      height: 6px;
      border-radius: 6px;
      background-color: white;
      transform: scale(0);
      display: inline-block;
      animation: dotanim 1.6s infinite ease-in-out;
      margin-right:2px;
    }
    .threedotloader .dot:nth-child(1) {
      animation-delay: 0s;
    }
    .threedotloader .dot:nth-child(2) {
      animation-delay: 0.25s;
    }
    .threedotloader .dot:nth-child(3) {
      animation-delay: 0.5s;
    }
    .threedotloader .dot:nth-child(4) {
      animation-delay: 0.75s;
    }

    @keyframes dotanim {
      0% {
        transform: scale(0);
      }
      50% {
        transform: scale(1);
      }
      90% {
        transform: scale(0);
      }
      100% {
        transform: scale(0);
      }
    }
</style>
