<template>
  <el-container style="height: 100vh">
    <el-aside class="aside-box" width="268px">
      <div class="new-chat" @click.stop="newchat">
        <img
          style="width: 16px; height: 16px; margin-right: 8px"
          :src="require('@/assets/add.png')"
          :fit="'fill'"
        />
        新建对话
      </div>
      <div class="aside-box-continer">
        <div class="aside-chat-box">
          <div class="chart-item" v-for="(item, index) in chatGroup">
            <div
              v-if="!item.editState"
              class="chart-item-wrap"
              :class="{ active: item.choose == true }"
              @click="choosechat(item)"
            >
              <div class="chart-msg">
                <img
                  v-if="item.choose == true"
                  style="width: 16px; height: 16px; margin-right: 4px"
                  :src="require('@/assets/msg-active.png')"
                  :fit="'fill'"
                />
                <img
                  v-else
                  style="width: 16px; height: 16px; margin-right: 4px"
                  :src="require('@/assets/msg.png')"
                  :fit="'fill'"
                />
                <div class="chart-msg-text">
                  {{ item.name }}
                </div>
              </div>
              <div v-if="item.choose" class="chat-button-group">
                <img
                  @click.stop="editchange(item, index)"
                  style="width: 16px; height: 16px; margin-right: 6px"
                  :src="require('@/assets/edit.png')"
                  :fit="'fill'"
                />
                <img
                  @click.stop="choosedelete(item)"
                  style="width: 16px; height: 16px; margin-right: 8px"
                  :src="require('@/assets/delete.png')"
                  :fit="'fill'"
                />
              </div>
            </div>
            <div
              v-else
              class="chart-item-wrap"
              :class="{ active: item.choose == true }"
            >
              <div class="chart-msg">
                <img
                  v-if="item.choose == true"
                  style="width: 16px; height: 16px; margin-right: 4px"
                  :src="require('@/assets/msg-active.png')"
                  :fit="'fill'"
                />
                <img
                  v-else
                  style="width: 16px; height: 16px; margin-right: 4px"
                  :src="require('@/assets/msg.png')"
                  :fit="'fill'"
                />
                <input
                  class="chart-item-input"
                  type="text"
                  ref="inputField"
                  v-model="item.name"
                />
              </div>
              <div class="chat-button-group">
                <img
                  @click.stop="saveinput(item, index)"
                  style="width: 16px; height: 16px; margin-right: 6px"
                  :src="require('@/assets/editOk.png')"
                  :fit="'fill'"
                />
                <img
                  @click.stop="endinput(item, index)"
                  style="width: 16px; height: 16px; margin-right: 8px"
                  :src="require('@/assets/editCancel.png')"
                  :fit="'fill'"
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="userifon-box">
        <div v-if="showLoginout" class="login-button" @click="poplogout">
          <img
            style="width: 16px; height: 16px; margin-right: 4px"
            :src="require('@/assets/logout.png')"
          />
          退出
        </div>
        <div class="login-user">
          <img
            style="width: 40px; height: 40px"
            :src="require('@/assets/user.png')"
            :fit="'fill'"
          />
          <div class="user-desc">
            <div class="user-name">
              {{ this.userinfo.nickName ? this.userinfo.nickName : "用户名称" }}
            </div>
            <div class="user-type">
              <img
                v-if="this.userinfo.vipType && this.userinfo.vipType > 1"
                style="width: 34px; height: 14px; margin-left: 0px"
                :src="require('@/assets/usertype.png')"
                :fit="'fill'"
              />
              <img
                v-else-if="this.userinfo.vipType && this.userinfo.vipType > 0"
                style="width: 34px; height: 14px; margin-left: 0px"
                :src="require('@/assets/usertype2.png')"
                :fit="'fill'"
              />
              <div v-else>普通用户</div>
            </div>
          </div>
        </div>
      </div>
    </el-aside>
    <el-container>
      <el-main class="main-box">
        <div v-if="noContent" class="main-content">
          <div class="main-content-box">
            <div class="main-title">妙语 AI</div>
            <div class="msg-desc-item" v-for="item in qussitionList">
              <div class="msg-desc-item-title">{{ item.title }}</div>
              <div
                class="msg-desc-item-text"
                @click="setQuession2(item2)"
                v-for="item2 in item.desc"
              >
                {{ item2 }}
                <img
                  class="think-icon"
                  :src="require('@/assets/arrow.png')"
                  :fit="'fill'"
                />
              </div>
            </div>
          </div>
        </div>
        <div v-else class="main-text-box">
          <div class="main-qa-box" ref="scrollmain">
            <div class="main-title">妙语 AI</div>
            <div class="msg-desc-item" v-for="item in contentList">
              <div class="main-content-quesssion">
                <img
                  class="quesssion-avator"
                  :src="require('@/assets/Avatar.png')"
                  :fit="'fill'"
                />
                <div class="quesssion-content">
                  <div class="quesssion-text">{{ item.quesssion }}</div>
                </div>
              </div>
              <div class="main-content-answer">
                <img
                  class="quesssion-avator"
                  :src="require('@/assets/logo.png')"
                  :fit="'fill'"
                />
                <div class="quesssion-content">
                  <div v-if="item.showdot" class="threedotloader">
                    <div class="'dot'"></div>
                    <div class="dot"></div>
                    <div class="dot"></div>
                  </div>
                  <div v-else class="quesssion-text">{{ item.answer }}</div>
                  <div v-if="item.showbutton" class="quesssion-button-bottom">
                    <div
                      class="quesssion-button-item"
                      @click="copy(item.answer)"
                    >
                      <img
                        class="quesssion-button-item-icon"
                        :src="require('@/assets/copy.png')"
                        :fit="'fill'"
                      />
                      复制
                    </div>
                    <!-- <div
                      @click="like(item)"
                      class="quesssion-button-item"
                      :class="{ active: item.like == true }"
                    >
                      <img
                        v-if="item.like == true"
                        class="quesssion-button-item-icon"
                        :src="require('@/assets/like2.png')"
                        :fit="'fill'"
                      />
                      <img
                        v-else
                        class="quesssion-button-item-icon"
                        :src="require('@/assets/like.png')"
                        :fit="'fill'"
                      />
                      喜欢
                    </div>
                    <div
                      @click="dislike(item)"
                      class="quesssion-button-item"
                      :class="{ disactive: item.dislike == true }"
                    >
                      <img
                        v-if="item.dislike == true"
                        class="quesssion-button-item-icon"
                        :src="require('@/assets/dislike2.png')"
                        :fit="'fill'"
                      />
                      <img
                        v-else
                        class="quesssion-button-item-icon"
                        :src="require('@/assets/dislike.png')"
                        :fit="'fill'"
                      />
                      不喜欢
                    </div> -->
                  </div>
                </div>
              </div>
              <div v-if="item.think.length > 0" class="main-content-think">
                <div
                  class="think-desc-item"
                  v-for="item2 in item.think"
                  @click="setQuession(item2, item)"
                >
                  <div class="think-content">
                    {{ item2 }}
                  </div>
                  <img
                    class="think-icon"
                    :src="require('@/assets/arrow.png')"
                    :fit="'fill'"
                  />
                </div>
              </div>
            </div>
            <div class="zhanwei"></div>
          </div>
        </div>
        <div class="main-input-box">
          <div v-if="showstop" @click="enterStop" class="stop-button">
            停止生成
          </div>
          <div class="large-wrap">
            <textarea
              v-model="input"
              class="large-textarea"
              ref="textarea"
              @keydown.enter="changeinput"
              @input="adjustTextareaHeight"
              @blur="inpuBlur"
              :disabled="!caninput"
              placeholder=""
            ></textarea>
            <div
              v-if="this.input.trim() != ''"
              @click="sureSendWay"
              class="sendbutton active"
            >
              发送
            </div>
            <div v-else class="sendbutton">发送</div>
          </div>

          <div class="checkbox-gpt4">
            <el-checkbox
              class="checkbox-text"
              :text-color="'#fff'"
              @change="changebox"
              v-model="checked"
              >GPT-4 剩余 {{ gptNumber }} 次</el-checkbox
            >
          </div>
        </div>
      </el-main>
    </el-container>
    <div v-if="showError" class="errorbox">
      <div class="errorbox-box">
        <el-image
          class="close-icon"
          :src="require('@/assets/close.png')"
          :fit="'fill'"
          @click="closeerror"
        />
        <div class="error-title">该账号没有体验权限</div>
        <div class="error-desc">
          开通妙语 AI app SVIP 可获得妙语 AI PC 端的使用权限
        </div>
        <a
          href="https://www.joychatai.com/download.html"
          target="_blank"
          class="error-button"
          >下载妙语 AI app</a
        >
      </div>
    </div>
    <login
      v-if="showLogin"
      @enterlogin="enterLogin"
      @login-success="loginSuccess"
      @login-error="loginError"
      @phoneError="phoneError"
    ></login>
    <div
      v-if="dialogFormVisible"
      style="width: 320px; border-radius: 8px"
      class="dialog"
    >
      <div class="dialog-title">确定要退出当前账号吗？</div>
      <div class="dialog-button-group">
        <div @click="loginout" class="dialog-button-confirm">确定</div>
        <div @click="cancelloginout" class="dialog-button-cancel">取消</div>
      </div>
    </div>
    <div
      v-if="dialogFormVisible2"
      style="width: 320px; border-radius: 8px"
      class="dialog"
    >
      <div class="dialog-title">确定要删除此对话吗？</div>
      <div class="dialog-button-group">
        <div @click="deleteSession" class="dialog-button-confirm">确定</div>
        <div @click="canceldelete" class="dialog-button-cancel">取消</div>
      </div>
    </div>
    <div v-if="showerrormsg" class="errormsg">
      {{ errormsg }}
    </div>
    <div
      v-if="dialogFormVisible3"
      style="width: 320px; border-radius: 8px"
      class="dialog"
    >
      <div class="dialog-title2">
        该账号已在其它设备/浏览器上登录。请重新登录
      </div>
      <div class="dialog-button-group">
        <div @click="canceldialog" class="dialog-button-confirm2">确定</div>
      </div>
    </div>
    <div v-if="showerrormsg" class="errormsg">
      {{ errormsg }}
    </div>
    <div v-if="isphone" class="phone-wrap">
      <div v-if="isWeixin" class="isWeixin">
        <img
          class="isWeixin-img"
          :src="require('@/assets/isWeixin.png')"
          alt=""
        />
      </div>
      <div class="phone-box">
        <div class="phone-title">
          <img
            style="width: 20px; height: 20px; margin-right: 6px"
            :src="require('@/assets/logo.png')"
            :fit="'fill'"
          />
          妙语 AI
        </div>
        <div class="phone-desc-list">
          <div class="phone-desc-item">
            <img
              style="width: 14px; height: 14px; margin-right: 4px"
              :src="require('@/assets/star.png')"
              :fit="'fill'"
            />
            GPT-3.5 Turbo
          </div>
          <div class="phone-desc-item">
            <img
              style="width: 14px; height: 14px; margin-right: 4px"
              :src="require('@/assets/star2.png')"
              :fit="'fill'"
            />
            GPT-4 Turbo
          </div>
        </div>
        <div class="phone-text">客户端下载</div>
        <div class="phone-text-desc">ChatGPT 官方 API，带你握手顶级 AI</div>
        <div class="phone-text-button" @click="download">点击这里一键下载</div>
        <div class="phone-divider">
          <div class="phone-line"></div>
          <div class="phone-divide-text">其他使用方式</div>
          <div class="phone-line"></div>
        </div>
        <div class="copy-title">复制链接前往电脑浏览器打开使用</div>
        <div class="copy-text">chat.joychatai.com</div>
        <div class="copy-button" @click.stop="copytext">复制链接</div>
      </div>
    </div>
  </el-container>
</template>

<script>
// @ is an alias to /src
import login from "@/components/login.vue";
import md5 from "md5";
import hljs from "highlight.js";
import { appMsg, apiMap } from "/api/config/apiMap.js";

function throttle(func, delay) {
  let lastCallTime = 0;
  return function () {
    const context = this;
    const args = arguments;
    const currentTime = Date.now();
    console.log(currentTime, lastCallTime);
    if (currentTime - lastCallTime >= delay) {
      func.apply(context, args);
      lastCallTime = currentTime;
    }
  };
}

export default {
  name: "Home",
  components: {
    login,
  },
  data() {
    return {
      input: "",
      isphone: false,
      isWeixin: false,
      errormsg: "",
      handClose: false,
      dialogFormVisible: false,
      dialogFormVisible2: false,
      dialogFormVisible3: false,
      showerrormsg: false,
      showLogin: false,
      showLoginout: false,
      orgSerialNo: null,
      canUse: false,
      chatGroup: [],
      showlife: false,
      showstop: false,
      userinfo: {},
      nosvip: false,
      websocket: null,
      noContent: true,
      checked: false,
      caninput: true,
      sessionId: null,
      contentList: [],
      gptNumber: 0,
      loading: {},
      deleteitem: null,
      chooseSessionId: null,
      showError: false,
      qussitionList: [
        {
          title: "工作",
          desc: [
            "我应该如何调整我的职业规划以在未来几年内获得提升？",
            "如何通过提升哪些技能来增强我的团队领导力？",
          ],
        },
        {
          title: "教育",
          desc: [
            "在中国的教育背景下，如何培养孩子的创新能力和批判性思维？",
            "帮我出20道口算题，两位数整数，使用加减法，并且结果小于100",
          ],
        },
        {
          title: "创意",
          desc: [
            "我正在创作一篇短篇小说，你能提供一些新颖、未曾使用过的情节线索吗？",
            "我正在创建一个新的网站，你能给我一些能改善用户体验的创新功能建议吗？",
          ],
        },
        {
          title: "科学",
          desc: [
            "人类的基因编码中存在哪些未被发现的秘密？",
            "人类会不会被机器人/人工智能取代？未来的世界会是什么样的？",
          ],
        },
        {
          title: "编程",
          desc: [
            "使用Python为我写一个俄罗斯方块的游戏",
            "深度学习的模型究竟在做什么？能给我一个直观的解释和展示吗？",
          ],
        },
        {
          title: "社交",
          desc: [
            "我买了“戴森吹风机”，它有数字马达，快速吹干头发。不伤头发。小巧，美观。帮我丰富一下，写小红书文案，配表情",
            "我今天游览了北京故宫，天气很好，我的心情也很好，帮我写三条朋友圈，多一些字数，配表情",
          ],
        },
        {
          title: "翻译",
          desc: [
            "如果我把一个中文惯用语，比如“千钧一发”，直译为英文，外国人会理解其含义吗？如果不会，那应该如何翻译能让他们理解其真正的含义？",
            "帮我用英文写一个演讲稿，介绍中国的传统节日，春节。包含这个春节的历史和传说，春节对于中国人的意义，中国人过春节的习俗。",
          ],
        },
        {
          title: "帮助",
          desc: ["如何使用妙语 AI？"],
        },
      ],
    };
  },
  mounted() {
    let that = this;
    // 获取窗口宽度
    const windowWidth = window.innerWidth;

    // 定义一个阈值，通常手机屏幕较窄，例如 768px
    const mobileThreshold = 768;

    // 检测窗口宽度是否小于阈值
    if (windowWidth < mobileThreshold) {
      console.log("当前设备是手机");
      this.isphone = true;
      // 在这里可以执行手机特定的逻辑
      const userAgent = navigator.userAgent.toLowerCase();

      // 判断用户代理字符串中是否包含 "MicroMessenger"
      const isWeixin = userAgent.includes("micromessenger");
      if (isWeixin) {
        // this.phoneError("是微信");
        this.isWeixin = true;
      }

      return;
    }
    // localStorage
    const token = localStorage.getItem("token");

    console.log('到这了',token)

    if (token) {
      this.loading = this.$loading({
        lock: true,
        text: "加载中...",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      const userinfo = localStorage.getItem("userinfo");
      const identification = localStorage.getItem("identification");
      const sign2 = md5(identification + appMsg.websocket);
      const visitorId = localStorage.getItem("visitorId");

      that.$axios({
          method: "GET", //指定请求方式
          url: apiMap.host + "/api/app/v1/user/info", //请求接口（相对接口，后面会介绍到）
          headers: {
            from: 1,
            version: "1.0.1",
            visitorId: visitorId,
            serial: new Date().getTime() + "",
          },
          params: {
            identification: identification, // 手机号
            sign: sign2,
          },
        })
        .then((res2) => {
          if (res2.data.errorCode == 10000 || res2.data.status == 400) {
            this.loginout();
            this.dialogFormVisible3 = true;
            return;
          }
          this.loading.close();
          localStorage.setItem("userinfo", JSON.stringify(res2.data.data));
          if (res2.data.data.count > 0) {
            this.loginSuccess(res2.data.data);
          } else {
            this.loginError(res2.data.data);
          }
          this.phone = "";
          this.code = "";
        })
        .catch((error) => {
          // 捕获请求错误
          this.loading.close();
          console.error("请求失败:", error);
          // 在这里可以处理请求失败的逻辑，例如显示错误信息
        });

      this.showLogin = false;
      this.canUse = true;
      this.userinfo = JSON.stringify(userinfo);
      let user = JSON.parse(userinfo);
      console.log(user.vipType,'用户vip')
      if (user.vipType > 1) {
        this.nosvip = false;
      } else {
        this.nosvip = true;
        return;
      }

      setTimeout(() => {
        that.loginSuccess(JSON.parse(userinfo));
      }, 500);
    } else {
      const url = window.location.href;

      // 使用正则表达式或其他方法来解析URL中的参数
      const params = new URLSearchParams(url.split("?")[1]);

      // 获取 code 参数的值
      const code = params.get("code");

      // 现在你可以在这里使用 code 参数
      console.log("code 参数的值是:", code);

      const wxsign = md5(code + appMsg.websocket);

      if (code) {
        this.loading = this.$loading({
          lock: true,
          text: "加载中...",
          spinner: "el-icon-loading",
          background: "rgba(0, 0, 0, 0.7)",
        });
        this.$axios({
          method: "post", //指定请求方式
          url: apiMap.host + "/api/web/v1/login/wx", //请求接口（相对接口，后面会介绍到）
          headers: {
            from: 1,
            version: "1.0.1",
            "Content-Type": "application/json",
          },
          data: {
            code: code, // 1: 获取登录验证码，2: 获取授权验证码
            sign: wxsign,
          },
        }).then((res) => {
          console.log(res, "微信登陆");
          if (res.data.status == 200) {
            localStorage.setItem("token", res.data.data.token);
            localStorage.setItem("visitorId", res.data.data.visitorId);
            localStorage.setItem(
              "identification",
              res.data.data.identification
            );
            document.cookie = "sessionid=" + res.data.data.token + ";";
            const sign2 = md5(res.data.data.identification + appMsg.websocket);
            that
              .$axios({
                method: "GET", //指定请求方式
                url: apiMap.host + "/api/app/v1/user/info", //请求接口（相对接口，后面会介绍到）
                headers: {
                  from: 1,
                  version: "1.0.1",
                  visitorId: res.data.data.visitorId,
                  Authorization: "basic " + res.data.data.token,
                  serial: new Date().getTime() + "",
                },
                params: {
                  identification: res.data.data.identification, // 手机号
                  sign: sign2,
                },
              })
              .then((res2) => {
                // if (res2.data.errorCode == 10000 || res2.data.status == 400) {
                //   that.loginout();
                //   return
                // }
                this.loading.close();
                localStorage.setItem(
                  "userinfo",
                  JSON.stringify(res2.data.data)
                );
                if (res2.data.data.vipType > 1) {
                  this.loginSuccess(res2.data.data);
                } else {
                  this.loginError(res2.data.data);
                }
                this.phone = "";
                this.code = "";
              })
              .catch((error) => {
                // 捕获请求错误
                console.error("请求失败:", error);
                // 在这里可以处理请求失败的逻辑，例如显示错误信息
              });
          }else{
            this.loading.close()
          }
        });
      }
    }
  },
  methods: {
    copytext() {
      const inputElement = document.createElement("textarea");
      inputElement.value = "chat.joychatai.com";
      document.body.appendChild(inputElement);
      inputElement.select();
      document.execCommand("copy");
      document.body.removeChild(inputElement);
      this.phoneError("链接已复制");
    },
    download() {
      let authUrl = "";

      const userAgent = navigator.userAgent;
      if (userAgent.match(/Android/i)) {
        console.log("当前设备是安卓");
        // 在这里可以执行安卓设备特定的逻辑
        authUrl = `https://quip.weixuanpro.cn/apk/release_v2.0.0.apk`;
        window.location.href = authUrl;
      } else if (userAgent.match(/iPhone|iPad|iPod/i)) {
        console.log("当前设备是iOS");
        authUrl = `https://itunes.apple.com/cn/app/6459354709?mt=8`;
        window.location.href = authUrl;
        // 在这里可以执行iOS设备特定的逻辑
      }
    },
    canceldialog() {
      this.dialogFormVisible3 = false;
    },
    phoneError(param) {
      console.log("触发了");
      this.closetoast();
      this.showerrormsg = true;
      this.errormsg = param;
      setTimeout(() => {
        this.closetoast();
      }, 3000);
    },
    closetoast() {
      this.showerrormsg = false;
      this.errormsg = "";
    },
    newchat() {
      if (this.canUse == false) {
        this.showLogin = true;
        return;
      }
      if (this.nosvip == true) {
        this.showError = true;
        return;
      }
      this.loading = this.$loading({
        lock: true,
        text: "加载中...",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      this.chatGroup.forEach((element) => {
        element.choose = false;
      });
      this.sessionId = null;
      this.noContent = true;
      if (this.websocket.readyState == 1) {
        console.log(this.websocket, this.websocket.readyState);
        this.handClose = true;
        this.websocket.close();
      }
      this.caninput = true;
      this.showstop = false;
      this.chooseSessionId = null;
      this.creatWebSocket();
      this.contentList = [];
      this.chooseSessionId = null;
    },
    cancelloginout() {
      this.dialogFormVisible = false;
    },
    loginout() {
      this.dialogFormVisible = false;
      this.sessionId = null;
      this.noContent = true;
      localStorage.removeItem("token");
      this.gptNumber = 0;
      this.caninput = true;
      this.showstop = false;
      this.contentList = [];
      this.userinfo = {};
      this.showLogin = false;
      this.showLoginout = false;
      this.chatGroup = [];
      this.canUse = false;
      this.dialogFormVisible = false;
      this.chooseSessionId = null;
    },
    poplogout() {
      this.dialogFormVisible = true;
    },
    changeinput(event) {
      let e = window.event || arguments[0];
      event.preventDefault();

      if (e.key == "Enter" && e.code == "Enter" && e.keyCode == 13) {
        if (!e.metaKey) {
          // e.metaKey == true证明是ctrl+enter
          e.returnValue = false;
          this.showstop = true;
          this.sureSendWay();
          return false;
        } else {
          this.question = this.question + "\n";
        }
      }
    },

    enterStop() {
      let time = new Date().getTime();
      let serialNo = this.userinfo.visitorId;
      let message = {
        command: "app-stop",
        serialNo: `${serialNo}${time}`,
      };
      console.log(
        this.contentList[this.contentList.length - 1].answer,
        this.contentList[this.contentList.length - 1].answer == ""
      );
      if (this.contentList[this.contentList.length - 1].answer == "") {
        this.contentList[this.contentList.length - 1].answer = "已停止生成";
        this.contentList[this.contentList.length - 1].showdot = false;
      }
      console.log(this.contentList);
      this.handClose = true;
      this.websocket.close();
      this.caninput = true;
      this.showstop = false;
      this.chooseSessionId = null;
      this.creatWebSocket();
    },
    changebox(val) {
      if (this.gptNumber < 1) {
        this.checked = false;
      }
      if (this.canUse == false) {
        this.showLogin = true;
        return;
      }
      if (this.nosvip == true) {
        this.showError = true;
        return;
      }
    },
    setQuession(param, parent) {
      if (this.canUse == false) {
        this.showLogin = true;
        return;
      }
      if (this.nosvip == true) {
        this.showError = true;
        return;
      }
      if (!this.caninput) {
        return;
      }
      this.caninput = false;

      this.orgSerialNo = parent.orgSerialNo;
      this.input = param;
      this.caninput = true;
      // this.adjustTextareaHeight()
    },
    inpuBlur(e) {
      console.log(e);
    },
    setQuession2(param) {
      if (this.canUse == false) {
        this.showLogin = true;
        return;
      }
      if (this.nosvip == true) {
        this.showError = true;
        return;
      }
      this.caninput = false;

      this.orgSerialNo = null;
      this.caninput = true;
      this.input = param; // 更新this.input的值
      this.adjustTextareaHeight(); // 更新textarea的高度
      // this.adjustTextareaHeight()
    },
    saveinput(item, index) {
      let that = this;
      const token = localStorage.getItem("token");
      const visitorId = localStorage.getItem("visitorId");
      const key = appMsg.websocket;
      const identification = localStorage.getItem("identification");
      const sessionId = this.chooseSessionId;
      const sign = md5(`${identification}${sessionId}${item.name}${key}`);

      this.$axios({
        method: "post", //指定请求方式
        url: apiMap.host + "/api/web/v1/chat/session/save", //请求接口（相对接口，后面会介绍到）
        headers: {
          from: 1,
          version: "1.0.1",
          Authorization: "basic " + token,
          visitorId: visitorId,
          sessionid: token,
          serial: new Date().getTime() + "",
        },
        data: {
          name: item.name, // 手机号
          sessionId: sessionId + "", // 1: 获取登录验证码，2: 获取授权验证码
          sign: sign,
        },
      }).then((res) => {
        console.log(res, "11111111");
        if (res.data.errorCode == 10000 || res.data.status == 400) {
          that.dialogFormVisible3 = true;
          that.loginout();
          return;
        }
        console.log(that.chatGroup);
        that.chatGroup[index].choose = true;
        that.chatGroup.forEach((element) => {
          element.editState = false;
        });
        that.$forceUpdate();
      });
    },
    endinput(item, index) {
      const token = localStorage.getItem("token");
      const visitorId = localStorage.getItem("visitorId");
      const key = appMsg.websocket;
      const identification = localStorage.getItem("identification");
      const sign2 = md5(`${identification}${key}`);
      let that = this;
      that
        .$axios({
          method: "get", //指定请求方式
          url: apiMap.host + "/api/web/v1/chat/session/list", //请求接口（相对接口，后面会介绍到）
          headers: {
            from: 1,
            version: "1.0.1",
            Authorization: "basic " + token,
            visitorId: visitorId,
            sessionid: token,
            serial: new Date().getTime() + "",
          },
          params: {
            sign: sign2,
          },
        })
        .then((res) => {
          if (res.data.errorCode == 10000 || res.data.status == 400) {
            that.dialogFormVisible3 = true;
            that.loginout();
            return;
          }
          that.chatGroup = res.data.data;
          that.chatGroup[0].choose = true;
          that.chatGroup.forEach((element) => {
            element.editState = false;
          });
          that.$forceUpdate();
        });
      // this.chatGroup.forEach((element) => {
      //   element.editState = false;
      // });
      // this.$set(this.chatGroup[index], "editState", true);
      // this.$forceUpdate();
    },
    editchange(item, index) {
      console.log(1111);
      this.chatGroup.forEach((element) => {
        element.editState = false;
      });
      this.$set(this.chatGroup[index], "editState", true);
      this.$forceUpdate();
      this.$nextTick(() => {
        console.log(this.$refs.inputField);
        this.$refs.inputField[0].focus(); // 自动获取焦点
      });
      console.log(this.chatGroup);
    },
    choosechat: throttle(function (item) {
      if (this.canUse == false) {
        this.showLogin = true;
        return;
      }
      if (this.nosvip == true) {
        this.showError = true;
        return;
      }
      this.loading = this.$loading({
        lock: true,
        text: "加载中...",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      if (this.websocket.readyState == 1) {
        console.log(this.websocket, this.websocket.readyState);
        this.handClose = true;
        this.websocket.close();
      }

      let that = this;
      this.chatGroup.forEach((element) => {
        element.choose = false;
        // element.editState = false;
      });
      item.choose = true;
      // item.editState = true;
      const token = localStorage.getItem("token");
      const visitorId = localStorage.getItem("visitorId");
      const key = appMsg.websocket;
      const identification = localStorage.getItem("identification");
      const sessionId = item.sessionId;
      const sign = md5(`${identification}${sessionId}${key}`);

      this.$axios({
        method: "get", //指定请求方式
        url: apiMap.host + "/api/web/v1/chat/lists", //请求接口（相对接口，后面会介绍到）
        headers: {
          from: 1,
          version: "1.0.1",
          Authorization: "basic " + token,
          visitorId: visitorId,
          sessionid: token,
          serial: new Date().getTime() + "",
        },
        params: {
          sessionid: item.sessionId + "",
          sign: sign,
        },
      }).then((res) => {
        if (res.data.errorCode == 10000 || res.data.status == 400) {
          this.dialogFormVisible3 = true;
          this.loginout();
          return;
        }
        this.creatWebSocket(sessionId);
        that.contentList = [];
        let arr = res.data.data;

        for (const item of arr) {
          // 检查项目的类型
          if (item.type == 1) {
            // 如果是问题，创建一个新对象并设置问题字段
            const newObject = {
              quesssion: item.content,
              answer: "已停止生成",
              showstop: false,
              showbutton: true,
              showdot: false,
              like: false,
              dislike: false,
              think: [],
              orgSerialNo: null,
            };
            // 将新对象添加到输出数组
            that.contentList.push(newObject);
          } else if (item.type === 2 && that.contentList.length >= 0) {
            console.log(item.content == "");
            // 如果是答案，并且输出数组不为空，将答案设置为最后一个对象的答案字段
            that.contentList[that.contentList.length - 1].answer =
              item.content == "" ? "已停止生成" : item.content;
          }
        }

        this.noContent = false;
        this.caninput = true;
        this.showstop = false;
        this.chooseSessionId = sessionId;

        // 输出转换后的数组
      });
    }, 800),
    closeerror() {
      this.showError = false;
    },
    sureSendWay(param) {
      if (this.canUse == false) {
        this.showLogin = true;
        this.showstop = false;
        return;
      }
      if (this.nosvip == true) {
        this.showError = true;
        this.showstop = false;
        return;
      }
      if (this.input.trim() == "") {
        // this.phoneError("不能输入空值")
        this.showstop = false;
        return;
      }
      if (this.input.trim() == "如何使用妙语 AI？") {
        // this.phoneError("错误");
        this.input = "";
        this.noContent = false;
        this.showlife = true;
        this.contentList = [
          {
            quesssion: "如何使用妙语 AI？",
            answer: `我是一个可以理解和生成人类语言的人工智能，因此你可以直接与我对话来表达你的需求。
你的问题描述的越具体详细，我就越能提供准确有用的答案。

例如：
“帮我出一份市场营销策划。” 更好的表达是 ：“帮我为一款运动饮料制定市场营销策划，我们是一个新的品牌，目标客户是在城市生活的年轻人。”

“写一篇发言稿” 更好的表达是 ： “我是一个科研工作者，我应邀去一个小学做演讲，请帮我写一篇以保护森林为主题的演讲稿。”

你还可以针对我的回答继续提问，更好的满足你的需求。

我的训练数据几乎涵盖了人类所有的知识，你有任何问题都可以与我探讨。

我被设计的初衷是为了更好的服务人类，人工智能并不会取代人类，我的存在是为了提升人类的生活质量和工作效率。唯有不会运用人工智能的人，才可能被新时代所淘汰。`,
            showstop: false,
            showdot: false,
            think: [],
            orgSerialNo: null,
          },
        ];
        return;
      }
      console.log(this.noContent, "测试");

      let that = this;
      const token = localStorage.getItem("token");
      const identification = localStorage.getItem("identification");
      const visitorId = localStorage.getItem("visitorId");
      const sessionId = this.sessionId;
      const name = this.input.slice(0, 20);
      const key = appMsg.websocket;
      const sign = md5(`${identification}${sessionId}${name}${key}`);
      const sign2 = md5(identification + appMsg.websocket);
      //

      if (this.userinfo.svip.end <= new Date().getTime()) {
        that
          .$axios({
            method: "GET", //指定请求方式
            url: apiMap.host + "/api/app/v1/user/info", //请求接口（相对接口，后面会介绍到）
            headers: {
              from: 1,
              version: "1.0.1",
              visitorId: visitorId,
              serial: new Date().getTime() + "",
            },
            params: {
              identification: identification, // 手机号
              sign: sign2,
            },
          })
          .then((res2) => {
            if (res2.data.errorCode == 10000 || res2.data.status == 400) {
              this.dialogFormVisible3 = true;
              this.loginout();
              return;
            }
            this.loading.close();
            localStorage.setItem("userinfo", JSON.stringify(res2.data.data));
            this.loginError(res2.data.data);
            this.phone = "";
            this.code = "";
          });

        return;
      }
      this.caninput = false;
      this.showstop = true;

      if (this.noContent) {
        this.$axios({
          method: "post", //指定请求方式
          url: apiMap.host + "/api/web/v1/chat/session/save", //请求接口（相对接口，后面会介绍到）
          headers: {
            from: 1,
            version: "1.0.1",
            Authorization: "basic " + token,
            visitorId: visitorId,
            sessionid: token,
            serial: new Date().getTime() + "",
          },
          data: {
            name: name, // 手机号
            sessionId: sessionId + "", // 1: 获取登录验证码，2: 获取授权验证码
            sign: sign,
          },
        }).then((res) => {
          const sign2 = md5(`${identification}${key}`);
          if (res.data.errorCode == 10000 || res.data.status == 400) {
            that.dialogFormVisible3 = true;
            that.loginout();
            return;
          }
          that
            .$axios({
              method: "get", //指定请求方式
              url: apiMap.host + "/api/web/v1/chat/session/list", //请求接口（相对接口，后面会介绍到）
              headers: {
                from: 1,
                version: "1.0.1",
                Authorization: "basic " + token,
                visitorId: visitorId,
                sessionid: token,
                serial: new Date().getTime() + "",
              },
              params: {
                sign: sign2,
              },
            })
            .then((res) => {
              if (res.data.errorCode == 10000 || res.data.status == 400) {
                that.dialogFormVisible3 = true;
                that.loginout();
                return;
              }
              that.chatGroup = res.data.data;
              that.chatGroup[0].choose = true;
              that.chatGroup.forEach((element) => {
                element.editState = false;
              });
            });
        });
      }
      let time = new Date().getTime();
      let serialNo = this.userinfo.visitorId;
      let message = {
        command: "app-quesion",
        serialNo: `${serialNo}${time}`,
        data: this.input,
        model: this.checked ? 2 : 1,
      };

      this.contentList.push({
        quesssion: "",
        answer: "",
        showdot: true,
        showbutton: false,
        think: [],
        orgSerialNo: null,
        like: false,
        dislike: false,
      });
      this.noContent = false;
      this.contentList[this.contentList.length - 1].quesssion = this.input;
      this.input = "";
      if (this.checked) {
        this.gptNumber = this.gptNumber - 1;
        this.userinfo.svip.count = this.gptNumber;
        localStorage.setItem("userinfo", JSON.stringify(this.userinfo));
      }

      if (this.gptNumber == 0) {
        this.checked = false;
      }
      this.websocket.send(JSON.stringify(message));
      const textarea = this.$refs.textarea;
      textarea.style.height = "33px"; // 先重置高度以便重新计算
      setTimeout(() => {
        this.$refs.scrollmain.scrollTop = this.$refs.scrollmain.scrollHeight
          ? this.$refs.scrollmain.scrollHeight
          : 0;
      }, 100);
      // textarea.style.height = textarea.scrollHeight + "px"; // 根据内容高度设置高度
    },
    adjustTextareaHeight() {
      console.log("触发了自动修改");
      const textarea = this.$refs.textarea;
      textarea.style.height = "33px"; // 先重置高度以便重新计算
      textarea.style.height = textarea.scrollHeight + "px"; // 根据内容高度设置高度
    },
    enterLogin() {
      this.showLogin = false;
    },
    copy(text) {
      const inputElement = document.createElement("textarea");
      inputElement.value = text;
      document.body.appendChild(inputElement);
      inputElement.select();
      document.execCommand("copy");
      document.body.removeChild(inputElement);
      this.phoneError("文案已复制");
    },
    like(param) {
      console.log(param);
      param.like = true;
      param.dislike = false;
      this.$nextTick();
    },
    dislike(param) {
      param.like = false;
      param.dislike = true;
    },
    loginSuccess(param) {
      this.canUse = true;
      this.nosvip = false;
      this.userinfo = param;
      this.gptNumber = param.svip.count;
      this.chooseSessionId = null;
      this.creatWebSocket();
      this.showLogin = false;
      this.showLoginout = true;
      const token = localStorage.getItem("token");
      const visitorId = localStorage.getItem("visitorId");
      const key = appMsg.websocket;
      const identification = localStorage.getItem("identification");
      const sign = md5(`${identification}${key}`);
      const time = new Date().getTime();
      const sign2 = md5(`${identification}020${time}${key}`);

      this.$axios({
        method: "get", //指定请求方式
        url: apiMap.host + "/api/web/v1/chat/session/list", //请求接口（相对接口，后面会介绍到）
        // url: "/re/api/web/v1/chat/session/list", //请求接口（相对接口，后面会介绍到）
        headers: {
          from: 1,
          version: "1.0.1",
          Authorization: "basic " + token,
          visitorId: visitorId,
          sessionid: token,
          serial: new Date().getTime() + "",
        },
        withCredentials: true,
        params: {
          sign: sign,
        },
      }).then((res) => {
        if (res.data.errorCode == 10000 || res.data.status == 400) {
          this.dialogFormVisible3 = true;
          this.loginout();
          return;
        }
        this.chatGroup = res.data.data;
        this.chatGroup.forEach((element) => {
          element.editState = false;
        });
      });
    },
    loginError(param) {
      this.canUse = true;
      this.nosvip = true;
      this.userinfo = param;
      this.showLogin = false;
      this.showLoginout = true;
      this.showError = true;
    },
    choosedelete(item) {
      this.deleteitem = item;
      this.dialogFormVisible2 = true;
    },
    canceldelete() {
      this.deleteitem = null;
      this.dialogFormVisible2 = false;
    },
    deleteSession() {
      let that = this;
      let item = this.deleteitem;
      const token = localStorage.getItem("token");
      const visitorId = localStorage.getItem("visitorId");
      const identification = localStorage.getItem("identification");
      const key = appMsg.websocket;
      const sessionId = item.sessionId;
      const sign = md5(`${identification}${sessionId}${key}`);

      this.$axios({
        method: "post", //指定请求方式
        url: apiMap.host + "/api/web/v1/chat/session/del", //请求接口（相对接口，后面会介绍到）
        headers: {
          from: 1,
          version: "1.0.1",
          Authorization: "basic " + token,
          visitorId: visitorId,
          sessionid: token,
          serial: new Date().getTime() + "",
        },
        data: {
          sessionId: item.sessionId + "",
          sign: sign,
        },
      }).then((res) => {
        if (res.status != 200) {
          console.log("删除失败");
        }
        if (res.data.errorCode == 10000 || res.data.status == 400) {
          this.dialogFormVisible3 = true;
          this.loginout();
          return;
        }
        const sign2 = md5(`${identification}${key}`);
        this.deleteitem = null;
        this.dialogFormVisible2 = false;
        this.noContent = true;
        that
          .$axios({
            method: "get", //指定请求方式
            url: apiMap.host + "/api/web/v1/chat/session/list", //请求接口（相对接口，后面会介绍到）
            headers: {
              from: 1,
              version: "1.0.1",
              Authorization: "basic " + token,
              visitorId: visitorId,
              sessionid: token,
              serial: new Date().getTime() + "",
            },
            params: {
              sign: sign2,
            },
          })
          .then((res) => {
            that.chatGroup = res.data.data;
            that.chatGroup.forEach((element) => {
              element.editState = false;
            });
            if (res.data.errorCode == 10000 || res.data.status == 400) {
              that.dialogFormVisible3 = true;
              that.loginout();
              return;
            }
          });
      });
    },
    onShow() {},
    editSession(item) {
      let that = this;
      const identification = localStorage.getItem("identification");
      const key = appMsg.websocket;
      const sessionId = item.sessionId;
      const sign = md5(`${identification}${sessionId}${key}`);

      this.$axios({
        method: "post", //指定请求方式
        url: apiMap.host + "/api/web/v1/chat/session/save", //请求接口（相对接口，后面会介绍到）
        headers: {
          from: 1,
          version: "1.0.1",
          Authorization: "basic " + token,
          visitorId: visitorId,
          sessionid: token,
        },
        params: {
          sessionid: item.sessionId + "",
          sign: sign,
        },
      }).then((res) => {
        if (res.data.errorCode == 10000 || res.data.status == 400) {
          that.dialogFormVisible3 = true;
          that.loginout();
          return;
        }
        const sign2 = md5(`${identification}${key}`);
        that
          .$axios({
            method: "get", //指定请求方式
            url: apiMap.host + "/api/web/v1/chat/session/list", //请求接口（相对接口，后面会介绍到）
            headers: {
              from: 1,
              version: "1.0.1",
              Authorization: "basic " + token,
              visitorId: visitorId,
              sessionid: token,
            },
            params: {
              sign: sign2,
            },
          })
          .then((res) => {
            if (res.data.errorCode == 10000 || res.data.status == 400) {
              that.dialogFormVisible3 = true;
              that.loginout();
              return;
            }
            that.chatGroup = res.data.data;
            that.chatGroup.forEach((element) => {
              element.editState = false;
            });
          });
      });
    },
    creatWebSocket(param, serialnum) {
      let that = this;
      let loadingg = this.loading;
      let serial;
      const visiterId = this.userinfo.visitorId;
      const identification = localStorage.getItem("identification");
      let sessionId;
      const key = appMsg.websocket;

      if (param) {
        sessionId = param;
      } else {
        sessionId = new Date().getTime();
      }

      if (serialnum) {
        serial = serialnum;
      } else {
        serial = new Date().getTime();
      }
      this.sessionId = sessionId;

      // 计算token
      // const token = md5(`${serial}${visiterId}${identification}${key}`);
      const token = md5(
        `${serial}${visiterId}${identification}${sessionId}${key}`
      );

      // 构建WebSocket地址

      const wsUrl = `wss://ws.airmx.com/quip/chat?token=${token}&serial=${serial}&visiterId=${visiterId}&identification=${identification}&version=1.0.1&from=1&sessionId=${sessionId}`;
      // const wsUrl = `wss://ws.joychatai.com/quip/chat?token=${token}&serial=${serial}&visiterId=${visiterId}&identification=${identification}&version=1.0.1&from=1&sessionId=${sessionId}`;

      // 创建WebSocket连接
      this.websocket = new WebSocket(wsUrl);

      // 监听WebSocket连接事件
      this.websocket.addEventListener("open", () => {
        console.log("WebSocket连接已建立");
        // alert("WebSocket连接已建立")
        loadingg.close();
        // console.log(this.websocket);
      });

      // 监听WebSocket消息事件
      this.websocket.onmessage = (event) => {
        // console.log("Received message:", event.data);
        const targetString = "quip-stop";
        const targetString2 = "quip-quesion";
        const targetString3 = "quip-refuse";
        const stopString = "app-stop";

        if (event.data.includes(targetString3)) {
          this.dialogFormVisible3 = true;
          this.loginout();
        } else if (event.data.includes(targetString)) {
          this.showstop = false;
          this.caninput = true;
          this.contentList[this.contentList.length - 1].showbutton = true;
          const inputString = event.data;
          const startIndex = inputString.lastIndexOf("-") + 1;
          const endIndex = inputString.lastIndexOf("#");
          const startIndex2 = inputString.lastIndexOf("_");

          if (startIndex !== -1 && endIndex !== -1) {
            const variablePart = inputString.substring(startIndex, endIndex);
            const variablePart2 = inputString.substring(
              startIndex + 1,
              endIndex - 1
            );
            this.contentList[this.contentList.length - 1].orgSerialNo =
              variablePart;
            console.log(variablePart2);
            if (variablePart2 < 1) {
            }
          } else {
            console.log("未找到匹配的字符串");
          }
        } else if (event.data.includes(targetString2)) {
          const regex = /\[([^[\]]*)\]/g;
          const regex2 = /(\d+)/g;
          const matches = event.data.match(regex);
          const matches2 = event.data.match(regex2);
          const number = parseInt(matches2[0]);
          const arrays = matches.map((match) => JSON.parse(match));
          this.contentList.forEach((element) => {
            if (element.orgSerialNo == number) {
              element.think = arrays[0];
              setTimeout(() => {
                this.$refs.scrollmain.scrollTop = this.$refs.scrollmain
                  .scrollHeight
                  ? this.$refs.scrollmain.scrollHeight
                  : 0;
              }, 300);
            }
          });
        } else {
          this.contentList[this.contentList.length - 1].showdot = false;
          this.contentList[this.contentList.length - 1].answer =
            this.contentList[this.contentList.length - 1].answer + event.data;
          this.$refs.scrollmain.scrollTop = this.$refs.scrollmain.scrollHeight
            ? this.$refs.scrollmain.scrollHeight
            : 0;
          setTimeout(() => {
            that.$refs.scrollmain.scrollTop = that.$refs.scrollmain.scrollHeight
              ? that.$refs.scrollmain.scrollHeight
              : 0;
          }, 300);
          this.showstop = true;
          this.caninput = false;
        }
        // that.contentList[this.contentList.length - 1].answer = event.data
        // 处理服务器返回的消息
      };

      //监听WebSocket关闭事件
      this.websocket.addEventListener("close", () => {
        // console.log("WebSocket连接已关闭");
        this.showstop = false;
        this.caninput = true;
        this.canUse = true;
        if (this.handClose) {
          this.handClose = false;
          return;
        } else if (this.chooseSessionId) {
          this.creatWebSocket(this.chooseSessionId);
        } else {
          this.creatWebSocket();
        }
        // 在这里执行断开连接后的操作，例如隐藏停止生成按钮
      });

      // 监听WebSocket错误事件
      this.websocket.addEventListener("error", (error) => {
        // this.phoneError("当前网络异常，请稍后重试");
        // console.error("WebSocket错误:", error);
      });
    },
  },
};
</script>

<style scoped lang="stylus">
@font-face {
  font-family: 'SourceHan'; /* 为字体定义一个名称 */
  src: url('/src/assets/SourceHanSansCN-Medium_1.woff') format('woff'); /* 指定字体文件的路径和格式 */
  /* 可以添加更多的格式和路径，以确保跨浏览器兼容性 */
}

* {
  font-family: 'SourceHan','PingFang SC' !important; /* 使用您定义的字体名称 */
}

.main-container {
  height: 100vh;
}
.aside-box-continer{
  width:100%;
  height:100%;
  overflow-y:scroll;
}
.aside-box {
  box-sizing: border-box;
  background-color: #2d2e31;
  padding: 16px 12px 80px;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  border-right: 1px solid rgba(255, 255, 255, 0.06);
  position: relative;

  .aside-chat-box {
    box-sizing: border-box;
    background-color: #2d2e31;
    padding: 16px 12px;
  }

  .userifon-box {
    width:220px;
    // height: 80px;
    /* background-color: #17ce92; */
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    flex-direction: column;
    position:absolute;
    bottom:12px;
    left:12px;
    background-color:#2d2e31;
    box-sizing:border-box;
    padding-top:12px;

    .login-button {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      flex-direction: row;
      color: #fff;
      font-family: 'SourceHan';
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 150%; /* 21px */
      cursor:pointer;
      margin-bottom:24px;
    }
  }
}
.login-user {
  display: flex;
  // padding: 8px;
  align-items: center;
  gap: 8px;
  align-self: flex-start;

  .user-desc {
    display: block;
  }
  .user-name {
    color: var(--White, #fff);
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%; /* 21px */
    margin-bottom: 4px;
  }
  .user-type {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    color: rgba(255, 255, 255, 0.7);
    font-family: Inter;
    font-size: 10px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%; /* 15px */
  }
}

.checkbox-gpt4{
  display flex;
  justify-content:center;
  align-items:center;
  margin: 12px auto 0;
}
.checkbox-text{
  /* 14/Regular */
  font-family: PingFang SC;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
  color: #FFF;

}
.new-chat {
  border-radius: 8px;
  border: 1px solid #17ce92;
  background: rgba(23, 206, 146, 0.1);
  box-sizing: border-box;
  width: 220px;
  padding: 12px;
  color: #17ce92;
  font-family: Source Code Pro;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%; /* 21px */
  display: flex;
  align-items: center;
  cursor: pointer;
}

.chart-item-wrap{
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  width:100%;
}

.chart-item-wrap.active .chart-msg {
  color:#17CE92;
}
.chart-item {
  width: 220px;
  padding: 12px 0px;
  margin-top: 12px;

  .chart-msg {
    display: flex;
    align-items: center;
    color: rgba(255, 255, 255, 0.7);
    font-family: 'SourceHan';
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%; /* 21px */
  }

  .chart-item-input{
    width:140px;
    border: 0.5px solid #17CE92;
    background:transparent;
    color: #FFF;
    font-family: Source Code Pro;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%; /* 21px */
  }
  .chart-item-input:focus {
    outline: none;
  }
  .chart-msg-text{
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width:148px;
  }
  .chat-button-group {
    display: flex;
    align-items: center;
  }
}


.chat-icon {
  margin-right: 8px;
}
.main-box {
  position: relative;
  padding: 0px 0 0;
  background-color: #202123;
  position: relative;
  height: 100%;
}
.main-title {
  text-align: center;
  color: #fff;
  font-family: 'SourceHan';
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 160%; /* 28.8px */
  margin:0 auto;
  padding: 16px 0 12px;
}
.main-content {
  height: 100%;
}
.main-qa-box{
  position: relative;
  height: 100%;
  display: block;
  border: 0 solid #d9d9e3;
  box-sizing: border-box;
  overflow: scroll;
  padding: 0 0px;
  box-sizing: border-box;
}
.main-content-box {
  position: relative;
  height: 100%;
  display: block;
  border: 0 solid #d9d9e3;
  box-sizing: border-box;
  overflow: scroll;
  padding: 0 160px 150px;
  box-sizing: border-box;
}
.main-text-box{
  position: relative;
  height: 100%;
  display: block;
  border: 0 solid #d9d9e3;
  box-sizing: border-box;
  overflow: scroll;
  padding: 0 0px 0px;
  box-sizing: border-box;
}
.main-input-box {
  width: 100%;
  background-color: #202123;
  position: absolute;
  bottom: 0;
  box-sizing: border-box;
  padding: 12px 160px;

  .large-wrap{
    width: 100%;
    display:flex;
    justify-content:space-between;
    align-items:center;

    .sendbutton{
      display: flex;
      width: 96px;
      height: 38px;
      justify-content: center;
      align-items: center;
      color: rgba(255, 255, 255, 0.50);
      font-family: PingFang SC;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px; /* 142.857% */
      margin-left:12px;
      border-radius:8px;
      background: #40414E;
      cursor:pointer;
    }
    .sendbutton.active{
      background: #17CE92;
      color: #FFF;
    }
  }

  .large-textarea{
    width: 100%;
    min-height: 38px;
    height: 38px;
    max-height: 104px !important;
    resize: none;
    background-color: #40414E;
    box-sizing: border-box;
    border: none;
    border-radius: 8px;
    box-shadow: none;
    color: rgba(255, 255, 255, 0.70);
    font-family: 'SourceHan';
    font-size:16px;
    font-weight: 400;
    line-height: 26px;
    padding: 6px;
    padding-left: 12px;
  }

  .large-textarea::selection {
    // background-color: transparent; /* 设置为透明背景 */
    // color: inherit; /* 使用继承的文字颜色 */
    // border: none;
  }
  .large-textarea:focus {
    outline: none;
  }

  .stop-button{
    width:136px;
    height:38px;
    border-radius: 66px;
    background: #343541;
    display: flex;
    justify-content:center;
    align-items:center;
    box-sizing:border-box;
    margin: 0 auto 12px;
    color: rgba(255, 255, 255, 0.90);
    text-align: center;
    font-family: 'SourceHan';
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 100%; /* 14px */
    cursor:pointer;
  }
}
/* 预设问题样式 */
.msg-desc-item {
  margin-bottom: 12px;
  //回答
  .main-content-quesssion{
    width:100%
    height:auto;
    display: flex;
    padding: 24px 160px;
    align-items: flex-start;
    justify-content:flex-start;
    background-color:#202123;
    box-sizing:border-box;
  }
  .main-content-answer{
    width:100%
    height:auto;
    display: flex;
    padding: 24px 160px;
    align-items: flex-start;
    justify-content:flex-start;
    background-color:#2D2E31;
    box-sizing:border-box;
  }
  .main-content-think{
    width:100%
    height:auto;
    padding: 24px 160px;
    align-items: flex-start;
    justify-content:flex-start;
    box-sizing:border-box;

    .think-desc-item{
      padding: 12px 4px 12px 8px;
      box-sizing: border-box;
      display:flex;
      justify-content:space-between;
      align-items: center;
      border-radius: 8px;
      background: #2D2E31;
      margin-bottom:12px;
      cursor:pointer

      .think-content{
        color: rgba(255, 255, 255, 0.70);
        /* 正文 32 160% */
        font-family: 'SourceHan';
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 160%; /* 25.6px */
      }
    }
  }
  .quesssion-avator{
      width:30px;
      height:30px;
      border-radius: 2px;
      margin-right:20px;
  }

  .quesssion-text{
      color: rgba(255, 255, 255, 0.70);
      /* 正文 32 160% */
      font-family: 'SourceHan';
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 160%; /* 25.6px */
      white-space: pre-line
  }
  .quesssion-content{

    .quesssion-button-bottom{
      margin-top 20px;
      display:flex;
      justify-content:flex-start;
      align-items:center;

      .quesssion-button-item.active{
        border: 0.5px solid #437B69;
        background: rgba(23, 206, 146, 0.10);
        color: #17CE92;
      }

      .quesssion-button-item.disactive{
        border: 0.5px solid #95473C;
        background: rgba(219, 68, 47, 0.10);
        color: #DB442F;
      }

      .quesssion-button-item{
        padding: 2px 8px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 33px;
        border: 0.5px solid #59596C;
        color: #ACACBE;
        font-family: 'SourceHan';
        font-size: 12px;
        font-style: normal;
        font-weight: 350;
        line-height: 150%; /* 18px */
        margin-right:12px;
        cursor:pointer;
      }
      .quesssion-button-item-icon{
        width: 12px;
        height: 12px;
        margin-right:2px;
      }
    }

    .threedotloader {
      margin: 0 auto;
      width: 30px;
    }
    .threedotloader .dot {
      width: 6px;
      height: 6px;
      border-radius: 6px;
      background-color: white;
      transform: scale(0);
      display: inline-block;
      animation: dotanim 1.6s infinite ease-in-out;
    }
    .threedotloader .dot:nth-child(1) {
      animation-delay: 0s;
    }
    .threedotloader .dot:nth-child(2) {
      animation-delay: 0.25s;
    }
    .threedotloader .dot:nth-child(3) {
      animation-delay: 0.5s;
    }
    .threedotloader .dot:nth-child(4) {
      animation-delay: 0.75s;
    }

    @keyframes dotanim {
      0% {
        transform: scale(0);
      }
      50% {
        transform: scale(1);
      }
      90% {
        transform: scale(0);
      }
      100% {
        transform: scale(0);
      }
    }
  }
  .msg-desc-item-title {
    color: #fff;
    font-family: 'SourceHan';
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 160%; /* 28.8px */
    margin-bottom: 8px;
  }
  .msg-desc-item-text {
    display: flex;
    padding: 12px 4px 12px 8px;
    width: 100%;
    border-radius: 8px;
    background: #2d2e31;
    margin-bottom: 12px;
    justify-content:space-between;
    cursor:pointer;
    align-items: center;
    gap: 8px;
    align-self: stretch;
    color: rgba(255, 255, 255, 0.7);
    /* 正文 32 160% */
    font-family: 'SourceHan';
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 160%; /* 25.6px */
  }
}
.zhanwei{
  width:100%;
  height:160px;
}
.errorbox{
  width:100%;
  height:100%;
  position:fixed;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.60);
  z-index:2

  .errorbox-box{
    width: 440px;
    height: 209px;
    border-radius: 8px;
    border: 1px solid #878787;
    background: #fff;
    padding: 40px 40px 24px;
    box-sizing: border-box;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  .error-title{
    color: #000;
    text-align: center;
    font-family: 'SourceHan';
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%; /* 27px */
    margin-bottom:12px
  }
  .error-desc{
    color: rgba(0, 0, 0, 0.50);
    text-align: center;
    font-family: 'SourceHan';
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%; /* 21px */
    margin-bottom:30px;
  }
  .error-button{
    width:360px;
    height:48px;
    margin:0 auto;
    display:flex;
    justify-content:center;
    align-items:center;
    border-radius: 4px;
    background: #17CE92;
    color: #FFF;
    font-family: 'SourceHan';
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    text-decoration: none;
  }
}
.close-icon {
  width:24px;
  height:24px;
  position: absolute;
  top: 12px;
  right: 12px;
  cursor: pointer;
}
.think-icon{
  width:24px;
  height:24px;
}
.dialog{
  position:absolute;
  top:50%;
  left:50%;
  transform:translate(-50%,-50%);
  background-color:#FFF;
}
.errormsg{
  position:absolute;
  top:50%;
  left:50%;
  transform:translate(-50%,-50%);
  background-color:#FFF;
  display: flex;
  padding: 8px 24px;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  background: #2B2F3A;
  backdrop-filter: blur(2px);
  color: #FFF;
  text-align: center;
  font-family: 'SourceHan';
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 21px; /* 150% */
  z-index 99;
}
.dialog-title2{
  width:100%;
  height:auto;
  box-sizing:border-box;
  padding:8px 16px;
  margin-top:16px;
  display:flex;
  justify-content:center;
  align-items:center;
  color: rgba(0, 0, 0, 0.90);
  text-align: center;
  font-family: 'SourceHan';
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%; /* 27px */
}
.dialog-title{
  width:100%;
  height:40px;
  margin-top:16px;
  display:flex;
  justify-content:center;
  align-items:center;
  color: rgba(0, 0, 0, 0.90);
  text-align: center;
  font-family: 'SourceHan';
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%; /* 27px */
}
.dialog-button-group{
  width:100%;
  height:40px;
  display:flex;
  margin-top:16px;
  justify-content:space-between;
  align-items:center;
  text-align: center;
  font-family: 'SourceHan';
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%; /* 24px */
}
.dialog-button-confirm2{
  width:100%;
  height:44px;
  display:flex;
  justify-content:center;
  align-items:center;
  color: rgba(0, 0, 0, 0.50);
  cursor pointer
}
.dialog-button-confirm{
  width:50%;
  height:44px;
  display:flex;
  justify-content:center;
  align-items:center;
  color: rgba(0, 0, 0, 0.50);
  cursor pointer
}
.dialog-button-cancel{
  width:50%;
  height:44px;
  display:flex;
  justify-content:center;
  align-items:center;
  color: #FF4034;
  cursor pointer
}
.el-dialog__header{
  padding:0;
}
.el-dialog__body{
  padding:0
}
::-webkit-scrollbar-thumb {
  display: none;
}

/* 兼容Firefox */
* {
  scrollbar-width: none;
}

*::-webkit-scrollbar {
  width: 0;
  height: 0;
}
// 手机端

.phone-wrap{
  width:100%;
  height: 100%;
  position:fixed;
  top:0;
  left:0;
  background: #666666;
  z-index:90;
  display:flex;
  justify-content:center;
  align-items:center;

  .isWeixin{
    width:100%;
    height: 100%;
    position:absolute;
    top:0;
    left:0;
    background: rgba(0, 0, 0, 0.60);
    z-index: 10;

    .isWeixin-img{
      width:172px;
      height: 70px;
      position:absolute;
      top:0;
      right:15px;
    }
  }

  .phone-box{
    width:304px;
    height: auto;
    background: #fff;
    border-radius:4px;
    padding:32px;

    .phone-title{
      color: #202123;
      text-align: center;
      font-family: 'SourceHan';
      font-size: 18px;
      font-style: normal;
      font-weight: 500;
      line-height: 150%; /* 27px */
      display:flex;
      justify-content:center;
      align-items:center;
    }
    .phone-desc-list{
      display:flex;
      justify-content:space-between;
      align-items:center;
      width:200px;
      margin:2px auto 20px;
      // margin-left:54px;

      .phone-desc-item{
        color: #202123;
        text-align: center;
        font-family: 'SourceHan';
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        line-height: 150%; /* 27px */
        display:flex;
        justify-content:center;
        align-items:center;
      }
    }
    .phone-text{
      color: #202123;
      text-align: center;
      font-family: 'SourceHan';
      font-size: 20px;
      font-style: normal;
      font-weight: 500;
      line-height: 150%; /* 30px */
    }
    .phone-text-desc{
      color: #202123;
      text-align: center;
      font-family: 'SourceHan';
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 150%; /* 30px */
      margin-top:2px;
    }
    .phone-text-button{
      width:240px;
      height:48px;
      margin: 20px auto 0;
      border-radius: 62px;
      border: 1px solid #17CE92;
      background: linear-gradient(128deg, #1FE36E 21.74%, #17CE92 78.26%);
      display:flex;
      justify-content:center;
      align-items:center;
      color: #FFF;
      text-align: center;
      font-family: 'SourceHan';
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 150%; /* 24px */
    }
    .phone-divider{
      width:240px;
      margin: 24px auto;
      display:flex;
      justify-content:space-between;
      align-items:center;

      .phone-line{
        width: 76px;
        height: 0.5px;
        background: #ADADAD;
      }
      .phone-divide-text{
        color: #ADADAD;
        text-align: center;
        font-family: 'SourceHan';
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 150%; /* 18px */
      }
    }
    .copy-title{
      color: #202123;
      text-align: center;
      font-family: 'SourceHan';
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 150%; /* 18px */
    }
    .copy-text{
      width 210px;
      margin:12px auto;
      height:32px;
      border-radius: 4px;
      border: 1px solid #17CE92;
      color: #17CE92;
      text-align: center;
      font-family: 'SourceHan';
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 32px; /* 24px */

    }
    .copy-button{
      width 72px;
      margin:0 auto;
      height:26px;
      border-radius: 44px;
      border: 1px solid #17CE92;
      color: #17CE92;
      text-align: center;
      font-family: 'SourceHan';
      font-size: 12px;
      font-style: normal;
      font-weight: 500;
      line-height: 26px; /* 18px */
    }
  }
}
</style>
