import Vue from 'vue'
import ElementUI from 'element-ui';
import './assets/element-variables.css'
import './assets/SourceHanSansCN-Medium.otf';
import 'element-ui/lib/theme-chalk/index.css';
import './assets/theme/index.css';
import App from './App.vue'
import router from './router'
import store from './store'
import axios from 'axios'//引入axios


Vue.config.productionTip = false
Vue.prototype.$axios = axios;//把axios挂载到vue上

Vue.use(ElementUI);

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
